import React, { Suspense } from "react";
import { render } from "react-dom";
import "./index.scss";
import "./utils/styles.scss";
import * as serviceWorker from "./serviceWorker";
import AppRoutes from "./utils/routes";
import LoaderComponent from "./components/LoaderComponent";
import { Provider } from "react-redux";
import store from "./utils/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./i18n/i18n";
import 'intl-pluralrules';

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

render(
  <div className="app-style">
    <Provider store={store}>
      <Suspense fallback={<LoaderComponent />}>
        <AppRoutes />
      </Suspense>
    </Provider>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
serviceWorker.unregister();

