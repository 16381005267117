import instance from "./externalService";

class LiveStreamingMetric {
  async postMetric(metric) {
    if (process.env.REACT_APP_NEED_METRICS === "true") {
      instance.post("/livestreamingevent", metric);
    }
  }

  async postAdImpresionMetric(metric) {
    // if (process.env.REACT_APP_NEED_METRICS === "true") {
    //   instance.post("/adImpression", metric);
    // }
  }

  async postAdICampaingMetric(metric) {
    if (process.env.REACT_APP_NEED_METRICS === "true") {
      instance.post("/campaignTracking", metric);
    }
  }
}

const liveStreamingMetric = new LiveStreamingMetric();

export default liveStreamingMetric;
