import React, { useState, useEffect } from "react";
import StarRating from 'react-svg-star-rating';
import styled from "styled-components";
import { useTranslation } from "react-i18next";


const WaveQualityComponent = (props) => {
  const {direction="row"}=props;
  const { t, i18n } = useTranslation();

  const RatingBox = styled.div`  

  width:max-content;
  padding: 0 !important;
  svg{
  width: 20px !important;
  height: 20px !important;
  margin-top: -3px !important;
  margin-right: 2px !important;
  margin-left: 1px !important;
  }

  path{
    ${ props.rating === 0 ? "fill: #ddd !important" : "" }
  }

  h6{
    display: flex;
    justify-content: center;
    span{
      display: flex;
      flex-direction: ${direction};
      margin-left: 5px;
    }
  }

  .blur-text{
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(3px);

  }

`;
console.log(direction);
  return (
    <RatingBox style={{display: (props.rating !== undefined || props.rating !== null)? "" : "none"}}>
      <h6 className={"post-title my-0 " + (props.needBlur === true ? "blur-text" : "")} >
        {/*t("forecast_wave_quality")*/} <StarRating  unit='half' size={15} count={5} initialRating={props.needBlur === true ? 0.5  : props.rating} edit={false} isReadOnly={true} color2="#dddddd" activeColor="#FABC41" />
      </h6>
    </RatingBox>
  )
}

export default WaveQualityComponent;
