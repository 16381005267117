import store from "../utils/store";

class PaymentErrorReducer {

  savePaymentStatus(status) {
    store.dispatch({
      type: "PAYMENT_STATUS",
      data: status,
    });
  }
}

const paymentErrorReducer = new PaymentErrorReducer();

export default paymentErrorReducer;