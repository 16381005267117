import constant from "../utils/constant";
import auth from "../modules/auth";
import utils from "../utils/utils";
import axios from "axios";

function getLocalAccessToken() {
  const accessToken = utils.getCookieValue(constant.COOKIE_AUTH);
  return accessToken;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_CORE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res["data"]["data"];
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await utils.refreshToken();
          const { token, newRefreshToken } = rs;
          if (token !== undefined && token !== null && token !== "") {
            utils.setCookie(token, newRefreshToken);
          }
          instance.defaults.headers.common["Authorization"] = "Bearer " + token;
          return instance(originalConfig);
        } catch (_error) {
          console.log("SERVICE ERROR ");
          console.log(_error);
          auth.logout();
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      else if (err.response.status === 401) {
        console.log("SERVICE AUTH ERROR ");
        console.log(err);
         auth.logout();
         window.location.href = "/";
      }
      // else if (err.response.status === 403 && err.response.data) {
      //   auth.logout();
      //   window.location.href = process.env.REACT_APP_BASE_HREF;
      // }
      // else if (err.response.status === 500 && err.response.data) {
      //   //auth.logout();
      // }
    }
    return Promise.reject(err);
  }
);



export default instance;
