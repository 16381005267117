import utils from "../utils/utils";
import axios from "axios";
import instance from "./service";
import constant from "../utils/constant";
import store from "../utils/store";
import multiSpotReducer from "../reducers/multiSpotReducer"

class Surfcam {
  async getFreeSurfCam() {
    const storeData = store.getState();
    let result = await instance.get("/getFreeSurfcam", {
      params: {
        country: storeData.country,
      },
    });
    return result;
  }

  async getCamList(spotId) {
    let result = await instance.get("/getSurfcams", {
      params: { spot_id: spotId },
    });
    return result;
  }

  async getRewind(spotId) {
    const result = await instance.get("/premium/getRewind", {
      Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      params: { spot_id: spotId },
    });
    return await this.sortRewinds(result);
  }

  async addMultispot(spots) {
    spots = this.formatMultispot(spots);
    await instance.post(
      "/premium/updateMultispot",
      { multispot: spots },
      {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      }
    );
    multiSpotReducer.saveSpots(spots);
    return true;
  }

  async sortRewinds(data) {
    let result = {};
    let auxMapIndex = {};
    let auxIndex = 0;

    for (let index = 0; index < data.length; index++) {
      const rew = data[index];
      if (result[rew["day"]] === undefined) {
        result[rew["day"]] = [];
        auxMapIndex[rew["day"]] = {};
        auxIndex = 0;
      }
      if (auxMapIndex[rew["day"]][rew["hour"]] === undefined) {
        result[rew["day"]].push({
          values: [],
          hour: rew["hour"],
        });
        auxMapIndex[rew["day"]][rew["hour"]] = auxIndex;
        auxIndex = auxIndex + 1;
      }
      result[rew["day"]][auxMapIndex[rew["day"]][rew["hour"]]]["values"].push({
        hour: rew["fullHour"],
        url: rew["url"],
        day: rew["day"],
      });
    }
    for (var m in result) {
      result[m] = result[m].reverse();
      for (let index = 0; index < result[m].length; index++) {
        const hour = result[m][index];
        result[m][index]["values"] = hour["values"].reverse();
      }
    }
    return result;
  }

  sortByDate(items) {
    items.sort(function compare(a, b) {
      var dateA = new Date(a.date);
      var dateB = new Date(b.date);
      return dateB - dateA;
    });
    return items;
  }

  async ratingSurfcam(data) {
    let retorno = null;
    await axios({
      method: "post",
      url: process.env.REACT_APP_CORE_URL + "/ratingSurfcam",
      headers: {
        Accept: "application/json",
      },
      data: data,
    })
      .then((res) => {
        retorno = res.data.data;
      })
      .catch(() => {
        return null;
      });
    return retorno;
  }

  async orderSurfcams(cams) {
    let orderByCity = {};
    for (let index = 0; index < cams.length; index++) {
      const cam = cams[index];
      if (orderByCity[cam["country"]] === undefined) {
        orderByCity[cam["country"]] = {};
      }
      if (orderByCity[cam["country"]][cam["city"]] === undefined) {
        orderByCity[cam["country"]][cam["city"]] = {
          name: cam["city"],
          surfbreaks: [],
        };
      }
      orderByCity[cam["country"]][cam["city"]]["surfbreaks"].push(cam);
    }
    return orderByCity;
  }

  async reOrderCams(orderByCity) {
    let response = [];
    for (var m in orderByCity) {
      if (utils.isUsingMobile() === false && orderByCity[m].name === "Maldonado") {
        response.splice(1, 0, orderByCity[m]);
      } else {
        response.push(orderByCity[m]);
      }
    }
    return response;
  }

  formatMultispot(multispot) {
    let result = [];
    for (let index = 0; index < multispot.length; index++) {
      const element = multispot[index].id;
      result.push(element);
    }
    return result.toString();
  }
}

const surfcam = new Surfcam();

export default surfcam;
