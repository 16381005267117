import store from "../utils/store";

class CheckoutReducer {

  checkout() {
    store.dispatch({
      type: "CHECKOUT",
      data: true,
    });
  }
}

const checkoutReducer = new CheckoutReducer();

export default checkoutReducer;