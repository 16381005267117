import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import CountDown from './contDown';
import selectPlanReducer from '../reducers/selectPlanReducer';
import plans from '../modules/plans';
import constant from '../utils/constant';
import utils from '../utils/utils';
import discountCodeService from "../modules/discountCodeService";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LoaderComponent from "./LoaderComponent";

const LineupSalePopUp = (props) => {
  const {user} = props;
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expireTime, setExpireTime] = useState(0);
  const start = new Date(2024, 7, 29); // month is 0 index, example August is 7
  const end = new Date(2024, 7, 31);
  const validCountries = ["UY", "AR"];

  useEffect(() => {
    const now = new Date();
    if(utils.getCustomCookie("COOKIE_50_OFF")) return;
    else utils.createCustomCookie("COOKIE_50_OFF", true, 0.25);
    setExpireTime(Math.floor((end - now) / 1000));
    if( now >= start && now <= end && validCountries.includes(user.country) && user.nextPlanId != constant.SUBSCRIPTION.ANNUALLY){
      utils.addScript(process.env.REACT_APP_DLOCAL_ENDPOINT);
      setShow(true);
    }
  }, []);

  const getDiscount = async () => {
    // CUANDO EMPEZE A PROGRAMAR ESTO DIOS Y YO SABIAMOS LO QUE HACE ESTA FUNCION... AHORA SOLO LO SABE DIOS.
    setLoading(true);
    setShow(false);
    const coupon = await discountCodeService.check("eanual20uy", constant.SUBSCRIPTION.ANNUALLY);
    const planList = await plans.getPlans(user.country);
    const currentPlan = planList[constant.SUBSCRIPTION.ANNUALLY];

    let totalAmount = parseInt(currentPlan.amount) + parseInt(currentPlan.saving_money);
    let couponDiscount = {
      amount: 20,
      coupon: 0,
      total: 0,
    };
    couponDiscount.coupon = utils.calculatePercentage(totalAmount, couponDiscount.amount);
    couponDiscount.total = parseFloat(currentPlan.saving_money) + couponDiscount.coupon;

    totalAmount -= totalAmount * 0.30;
    let planAmount = {
      total: Number(totalAmount - (couponDiscount.coupon)).toFixed(2),
      monthly: Number(utils.calculeMonthlyAmount(currentPlan, couponDiscount.coupon).toFixed(2)), //corresponde al valor mensual del plan. En el caso del plan mensual va a ser igual al total 
    };


    delete planList[constant.SUBSCRIPTION.ANNUALLY];
    
    
    selectPlanReducer.selectPlan(
      currentPlan,
      coupon,
      null,
      couponDiscount,
      planAmount,
      planList,
    );

    history.push({
      pathname: "/premium/checkout",
      search: window.location.search,
    });
  }

  return (
    <>
    {
      show && (
        <PopUpOverlay>
          <PopUpContainer>
            <InfoContainer>
              <h1 className='title'>
                <span>DESCUENTO FLASH</span> LINEUP PREMIUM
              </h1>
              <span className='infoSpan' style={{width: "fit-content",}}>
                <h1 className='title off' style={{color: "#004986", width: "fit-content",}}>
                  50% OFF
                </h1>
                <p style={{ margin: 0, width: "340px"}}>Solo este fin de semana, en la subscripción anual.</p>
              </span>
              <CountDown time={expireTime} />
              <PopUpButtonContainer>
                <PopUpButton onClick={() => getDiscount()}>Usar el descuento</PopUpButton>
                <PopUpButton className='cancel' onClick={() => setShow(false)}>Me lo pierdo</PopUpButton>
              </PopUpButtonContainer>
            </InfoContainer>
          </PopUpContainer>
        </PopUpOverlay>
      )
    } 
    {
      loading && (
        <div style={{background: "#fff", width: "100%", height: "100vh", position: "fixed", top: 0, zIndex: 9999}}>
          <LoaderComponent/>
        </div>
      )
    }
    </>
  )
}

const PopUpContainer = styled.div`
  display: flex;
  background: #fff;
  width: 500px;
  height: 705px;
  padding: 50px 30px;
  text-align: center;

  @media(max-width: 1000px){
    width: 100%;
    height: 100vh;
  }
`

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;


  .title{
    color: #000;
    font-size: 35px;
    font-weight: 900;
    margin: 0 auto;

    span{
      display: block;
      width: 100%;
    }
  }

  .off{ font-size: 80px }
  .infoSpan{ margin: 50px auto }

  p{
    font-size: 20px;
  }

  @media(max-width: 1000px){
    .title{ font-size: 26px }

    .off{ font-size: 70px }

    .infoSpan{ margin: auto; margin-bottom: 50px; }
  }
`

const PopUpButtonContainer = styled.ul`
  padding: 0 !important;
  color: #fff;
  margin-bottom: 0px;
  width: 100%;
  margin-top: auto;

  li:first-child button {
    background: #31c241;
    border: none;
    width: 100%;
    border-radius: 0 0 0.25em 0.25em;
  }

  .cancel{
    background: rgb(66 87 129);
    padding: 6px 12px;
  }
`;

const PopUpButton = styled.li`
  width: 100% !important;
  list-style: none !important;
  list-style-type: none !important;
  text-align: center;
  display: block;
  color: #fff;
  transition: background-color 0.2s;
  background-color: #31c241;
  font-size: 1.1em;
  font-weight: 600;
  border-radius: 5px;
  margin: 5px 0;
  padding: 12px;
  cursor: pointer;
`;

const PopUpOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, .2);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(LineupSalePopUp)
