import store from "../utils/store";

class SocketReducer {

  stopSreaming() {
    store.dispatch({
      type: "HANDLE_SOCKET",
      data: {
        action: "STOP_STREAMING",
        payload: {}
      }
    });
  }
  
  logout() {
    store.dispatch({
      type: "HANDLE_SOCKET",
      data: {
        action: "LOGOUT",
        payload: {}
      }
    });
  }
}

const socketReducer = new SocketReducer();

export default socketReducer;