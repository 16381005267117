import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Select} from 'antd'; 

const DropDownMenu = (props) => {
    const {title, list, key, onSelect, state} = props;

    return (
        <DropDownContainer className='select-list'>
            <label for={key} className="select-list-label">{title}</label>
            <Select value={state} id={key} className="filter-dropdown" style={{ width: 150 }} listHeight={ (32*list.length) } options={list} onChange={onSelect}/>
        </DropDownContainer>
    )
}

const DropDownContainer = styled.div`
    justify-content: flex-start;
    padding: 0px 0;

    @media (max-width: 1000px) {
    padding: 0px;
    margin-top: 0px;
    }

    .select-list-label {
    margin-right: 10px;
    margin-top: 13px;
    width: 100px;
    }

    div:nth-child(1){
    margin-left: -5px;
    }

    .ant-select-arrow{
    margin-right: 19px;
    }
`;

export default DropDownMenu;