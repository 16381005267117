import store from "../utils/store";

class DeviceReducer{

    setDeviceInfo(isPwa, isMobile, isAppleDevice){
        store.dispatch({
            type: "DEVICE_INFO",
            data: {isPwa: isPwa, isMobile: isMobile, isAppleDevice: isAppleDevice}
        });
    }

}

const deviceReducer = new DeviceReducer();

export default deviceReducer;