import React, { Component, useEffect, useState, useRef, Fragment } from "react";
import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";
import { Spinner } from "reactstrap";
import '../views/surfcamList/surfcamList.scss'
import { Table, Input } from "reactstrap";
import { AiFillWarning, AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import utils from "../utils/utils";

const ClipsComponent = (props) => {
  const [selectedHour, setSelectedHour] = useState("");
  const [scrollHeiht, setScrollHeight] = useState(0);
  const [scrollUpMax, setScrollUpMax] = useState(true);
  const [scrollDownMax, setScrollDownUpMax] = useState(false);
  const container = useRef();

  useEffect(() => {
    if (props.hours.length > 0 && selectedHour === "") {
      const currentDay = props.hours[0];
      setSelectedHour(currentDay.key);
    }
  }, [props.hours, props.currentVideo]);

  useEffect(() => {}, [props.currentVideo]);

  const scrollHandle = (positionAux, scrolling) => {
    let position = scrollHeiht + positionAux;
    setScrollHeight(position);
    const maxScroll = container.current.getElement().scrollHeight;
    if (position >= maxScroll) {
      setScrollDownUpMax(true);
      setScrollHeight(maxScroll);
    } else {
      setScrollDownUpMax(false);
      if (position <= 0) {
        setScrollUpMax(true);
        setScrollHeight(0);
      } else {
        setScrollUpMax(false);
      }
    }
    container.current.getElement().scroll({
      top: position,
      behavior: "smooth",
    });
  };

  const cleanNavigation = () => {
    setScrollHeight(0);
    setScrollUpMax(true);
    setScrollDownUpMax(false);
  };

  const handleHour = (hourValue) => {
    cleanNavigation();
    setSelectedHour(hourValue);
    props.setVideoUrl(props.rewinds[hourValue][0].values[0])
    container.current.getElement().scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {selectedHour !== "" ? (
        <RewindContainer>
          <SelectBox onChange={(e) => handleHour(e.target.value)}>
            {props.hours &&
              props.hours.length > 0 &&
              props.hours.map((hour, index) => {
                return <option value={hour.key} key={"clipHour" + index}>{hour.name}</option>;
              })}
          </SelectBox>
          <ScrollContainer style={{ display: "flow-root", maxHeight: "390px", maxWidth: "100%", overflowX: "hidden" }} vertical={true} horizontal={false} ref={container}>
            <AiOutlineArrowRight className="arrow-icon-list scroll-button-down" onClick={() => scrollHandle(370, true)} hidden={scrollDownMax} />
            <AiOutlineArrowLeft className="arrow-icon-list  scroll-button-top" onClick={() => scrollHandle(-370, false)} hidden={scrollUpMax} />

            {props.rewinds[selectedHour].map((hourItem, key) => {
              return (
                <Fragment key={"rewinds" + key}>
                  <HourTitle>{hourItem["hour"]} hs</HourTitle>
                  <div className="row gy-4">
                    {hourItem["values"].map((hourData, index) => {
                      return (
                        <div className="col-6 up-margin-camrewind-clip" key={"hourData" + index}>
                          <RewindBox
                            className={props.currentVideo["day"] === hourData["day"] && props.currentVideo["hour"] === hourData["hour"] ? "active" : ""}
                            style={{ backgroundImage: 'url("https://lineup-chatbot-images.s3.amazonaws.com/surfbreaks/cover/31.jpg")' }}
                            onClick={() => props.setVideoUrl(hourData)}>
                            {props.currentVideo["day"] === hourData["day"] && props.currentVideo["hour"] === hourData["hour"] ? <div className="overlay-active" /> : <div className="cover-overlay" />}
                            <h2>{hourData.hour.substring(0, hourData.hour.length - 3)}</h2>
                          </RewindBox>
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              );
            })}
          </ScrollContainer>
        </RewindContainer>
      ) : (
        <SpinnerBox>
          <Spinner>Loading...</Spinner>
        </SpinnerBox>
      )}
    </>
  );
};

const RewindContainer = styled.div`
  .active {
    background-image: none !important;
    background-color: #fff;
    border: 2px solid #004989;
    h2 {
      color: #004989;
    }
  }
`;

const SpinnerBox = styled.div`
  text-align: center;

  .spinner-border {
    width: 3rem;
    height: 3rem;
    margin-top: 30%;
  }
`;

const RewindBox = styled.div`
  height: 80px;
  width: 100%;
  background-size: cover;
  border-radius: 10px;
  cursor: pointer;

  h2 {
    position: sticky;
    color: #ffff;
    z-index: 99;
    margin-top: -50px !important;
    margin-top: -120px;
    font-size: 1.2em;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .overlay-active {
    position: relative;
    height: 100%;
    width: 100%;
  }
`;

const HourTitle = styled.h2`
  font-size: 1.2em;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SelectBox = styled.select`
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(53, 84, 130) !important;
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 10px;

  option{
    text-transform: capitalize;
  }

`;

export default ClipsComponent;
