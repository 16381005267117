import React, {  useState }from 'react';
import styled from 'styled-components';
import { AiOutlineClose } from "react-icons/ai";
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton,TelegramShareButton, FacebookIcon, WhatsappIcon, TwitterIcon, TelegramIcon } from "react-share";
import { useTranslation } from "react-i18next";

const ShareComponent = (props) => {
    const { t } = useTranslation();
    const { dialogId, title, url, separator } = props;
    
    const copyToClipboard = (event) => {
        event.preventDefault();
        document.getElementById("copyElement").select();
        document.execCommand("copy");
    }

  return (
    <Container>
        <dialog id={dialogId}>
            <ShareContainer method='dialog'>
                <button className='close-button'>
                    <AiOutlineClose className="close-icon"/>
                </button>
                <h2>{t("share_button_title")}</h2>
                <SocialContainer > 

                    <WhatsappShareButton url={url} title={title} separator={separator} >
                        <WhatsappIcon size={60} round={true}/>
                        <span>Whatsapp</span>
                    </WhatsappShareButton>

                    <FacebookShareButton url={url} >
                        <FacebookIcon size={60} round={true}/>
                        <span>Facebook</span>
                    </FacebookShareButton>

                    <TwitterShareButton url={url} title={title} separator={" - "} >
                        <TwitterIcon size={60} round={true}/>
                        <span>Twitter</span>
                    </TwitterShareButton>

                    <TelegramShareButton url={url} title={title} >
                        <TelegramIcon size={60} round={true}/>
                        <span>Telegram</span>
                    </TelegramShareButton>

                </SocialContainer>
                <LinkContainer >
                    <input type="text" id="copyElement" value={url} />
                    <button onClick={ (e) => copyToClipboard(e) }>{t("share_button_copy")}</button>
                </LinkContainer>
            </ShareContainer>
        </dialog>
    </Container>
  )
}

const Container = styled.div`
    dialog::backdrop{
        background-color: rgba(94, 110, 141, 0.9);
    }

    dialog{
        background: transparent;
        border: none;
          
    }
`

const ShareContainer = styled.form`
    width: 500px;
    height: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    background: #fff;
    margin: 4em auto;
    border-radius: 0.25em 0.25em 0.4em 0.4em;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    padding: 0 24px;

    > h2 {
        position: absolute;
        left: 20px;
        top: 20px;
        font-size: 16px;
        
    }

    .close-button{
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        border: none;
        background: transparent;

        > svg {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            padding: 5px;
            z-index: 999;
            stroke-width: 20;
          }
    }
    

    @media(max-width: 1000px){
    width: 100%;
    }
`

const SocialContainer = styled.div`
    width: 100%;
    height: 120px;
    margin: 50px 0 0 0;
    display: flex;

    button {
        margin: 0 20px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "Poppins", sans-serif !important;
        font-size: 12px !important;
    }

    button span {
        margin: 5px 0 0 0;
    }

    @media(max-width: 500px){
        overflow: scroll;
    }
`

const LinkContainer = styled.div`
      width: 100%;
      height: 60px;
      margin: 25px 0 0 0;
      background: #f8f8f8;
      border-radius: 0.25em 0.25em 0.4em 0.4em;
      border: 1px solid #55555552;
      color: rgba(0,0,0,0.701);
      display: flex;
      align-items: center;
      
      input{
        width: 100%;
        font-family: "Poppins", sans-serif;
        margin-left: 16px;
        border: none;
        background: transparent;
        outline: none;
        text-overflow: ellipsis;

        @media(max-width: 1000px){
            max-width: 180px;
        }
      }

      button{
        width: 80px;
        height: 40px;
        background-color: #425781;
        color: #fff;
        border-radius: 5px;
        text-align: center;
        border: none;
        margin: 0 16px;
      }
`


export default ShareComponent