import constant from "../utils/constant";
import utils from "../utils/utils";

class AffiliateService {

    getUrlAffiliate() {
        const urlParams = new URLSearchParams(window.location.search);
        const affilate = urlParams.get('affiliate');
        return affilate;
    }

    getAffiliate() {
        const existingAffiliate = utils.getCustomCookie(constant.COOKIE_AFFILIATE);
        return existingAffiliate;
    }

    addAffiliate(affilate) {
        utils.createCustomCookie(constant.COOKIE_AFFILIATE, affilate, 20);
    }
}
const affiliateService = new AffiliateService()
export default affiliateService;