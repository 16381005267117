import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { RiCloseFill } from "react-icons/ri";
import { AiFillWarning } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import PropTypes from "prop-types";

const WarningContainer = (props) => {
  const { type, bePremium, daysLeft, isAB, message } = props;
  const { t } = useTranslation();
  const [colors, setColors] = useState([]);
  const [hidden, setHidden] = useState(false);

  const isAlert = type === "alert";
  const isInfo = type === "info";
  useEffect(() => {
    isInfo ? setColors(["#0b6dd9", "#e5effa", "#0b6dd9"]) : isAlert ? setColors(["#ef9502", "#fef8ea", "#f9e2c0"]) : setColors(["#ed4f2d", "#fcede9", "#f2cec6"]);
  }, []);

  const Warning = styled.div`
    height: 55px;
    background: ${colors[1]}; //Light
    border: 1px solid ${colors[2]}; //border
    padding: 10px 75px;
    // padding-left: 75px;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 15px !important;

    p {
      margin: 0;
      margin-left: 5px;
      font-family: Poppins;
      font-size: 0.9rem;
    }

    svg {
      width: 40px;
      height: 40px;
      color: #fff;
      left: 15px;
      position: absolute;
      background: ${colors[0]}; //Bold
      padding: 8px;
      border-radius: 14px;
      border: 2px solid ${colors[2]}; //Border
    }

    svg:nth-of-type(2) {
      width: 30px;
      height: 30px;
      left: initial;
      right: 15px;
      padding: 2.5px;
      background: #fff;
      color: #000;
      cursor: pointer;
      border: none;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      height: initial;
      padding-left: 60px;
      padding-right: 60px;

      p {
        text-align: center;
        margin: 0;
      }

      a {
        padding: 0 !important;
      }

      svg {
        // top: calc(50% - 15px);
        right: 15px;
      }
    }
  `;

  const id = isInfo ? "info" : isAlert ? "alert" : "warning";
  return (
    <>
      {!hidden && (
        <Warning id={id}>
          {isInfo && daysLeft > 0 && (
            <>
              <>
                <IoMdInformationCircleOutline stroke="#fff" />
                <p>
                  <Trans
                    i18nKey={bePremium ? t("multicam_info") : t("be_free_cam")} // optional -> fallbacks to defaults if not provided
                    values={{ days: daysLeft }}
                  />
                </p>
              </>
            </>
          )}
          {isInfo && isAB ? (
            <>
              <>
                <IoMdInformationCircleOutline stroke="#fff" />
                <p>
                  <Trans
                    i18nKey={t("sell_live_cameras")} // optional -> fallbacks to defaults if not provided
                  />
                  <Link to="/premium" style={{ textDecoration: "underline" }}>
                    {t("sell_live_cameras_link")}
                  </Link>
                </p>
              </>
            </>
          ) : (
            isInfo &&
            !isAB && (
              <>
                <>
                  <IoMdInformationCircleOutline stroke="#fff" />
                  <p>
                    <Trans i18nKey={message} />
                  </p>
                </>
              </>
            )
          )}
          {isAlert && (
            <>
              <AiFillWarning />
              <p>{t("text_few_minutes")}&nbsp;</p>
              <Link to={"/premium"}>
                <strong>{t("text_few_minutes_buy_premium")}</strong>
              </Link>
              <RiCloseFill onClick={() => setHidden(true)} />
            </>
          )}
          {type === "warning" && (
            <>
              <AiFillWarning />
              <p>
                {t("cam_player_connection_issue_1")}{" "}
                <span>
                  <a href="https://bit.ly/lineup-support" target="_blank">{t("support")}</a>
                </span>
                {t("cam_player_connection_issue_2")}
              </p>
            </>
          )}
        </Warning>
      )}
    </>
  );
};

WarningContainer.propTypes = {
  type: PropTypes.string.isRequired,
};

export default WarningContainer;
