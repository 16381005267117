import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const WaveHeightTooltip = (props) => {
    const { index } = props;
    const [active, setActive] = useState(false);
    const { t } = useTranslation();

    return (
        <Tooltip placement={isMobile ? "top" : "left"} isOpen={active} target={index} toggle={() => setActive(!active)}>
            {t("forecast_issue")}
        </Tooltip>
    )
}

export default WaveHeightTooltip;