import constant from "../utils/constant";

export const createStreamingPlayList = (surfCamImg, streamUrl, lastRewinds, isWizzard, user) => {
    let playlist = [];
    if (user.isPremium === true || isWizzard === true) {
        playlist.push({
            file: streamUrl,
            image: surfCamImg,
        })
        return playlist;
    } else {
        playlist.push({
            file: "https://images-store.lineup.surf/videos/add3.mp4",
            image: surfCamImg,
        })
        if (user.usingAB === true) {
            for (let index = 0; index < lastRewinds.length; index++) {
                const element = lastRewinds[index];
                playlist.push({
                    file: element.url,
                    image: surfCamImg,
                })
            }
        } else {
            playlist.push({
                file: streamUrl,
                image: surfCamImg,
            })
        }
    }
    return playlist;
}

export const needCheckStreaming = (user, timeWatched, isWizzard) => {
    return user.currentPlanId === constant.SUBSCRIPTION.FREE && timeWatched >= 5 && isWizzard !== true && user.usingAB !== true
}

export const handleTextAB = (user, i18nInstance, key) => {
    if (user?.usingAB === true) {
        key = key + "_ab";
    }
    return i18nInstance(key)
}

export const blockActionAB = (user) => {
    return user?.usingAB !== true
}

export const showActionAB = (user) => {
    return user?.usingAB === true
}

