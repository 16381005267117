import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Input, InputGroupText, InputGroup } from "reactstrap";
import styled from "styled-components";
import { AiOutlineSearch } from "react-icons/ai";
import utils from "../utils/utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import spotService from "../modules/spotService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faXmark } from "@fortawesome/free-solid-svg-icons";
import '../utils/styles/searchbar.css';
import constant from "../utils/constant";
import store from "../utils/store";

const Option = (props) => {
  const {country, cities, selectMethod, mainCountry} = props;
  const [isOpen, setIsOpen] = useState(country === constant['AVAILABLE_COUNTRIES'][mainCountry]);

  return (
    <div style={{
      width: '100%',
    }}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="searchbar-option-country"
      >
        {country} {isOpen ? <FontAwesomeIcon style={{marginLeft: '20px'}} icon={faChevronUp} /> : <FontAwesomeIcon style={{marginLeft: '20px'}} icon={faChevronDown} />}
      </div>
      <div className="searchbar-option-spot-list">
        { isOpen && (
          Object.keys(cities).map( city => {
            return (
              <>
                {cities[city].map( spot => (
                  <Link onClick={() => selectMethod()} to={utils.getSpotUrl(spot.country, spot.city, spot.url) + (spot.multicam === true ? `/${spot.position_camera}` : "")} className="searchbar-option-spot">
                    <div className="searchbar-option-spot-cam" style={{
                      background: spot.surfcam ? 
                        spot.is_premium ?  
                          '#fabc41' : 
                          '#016de7' : 
                        'transparent',
                    }}>
                      { spot.surfcam && <img crossOrigin="anonymous" className="icon-cam" src={require("../assets/icons/cam_white.svg")} alt="cam icon" /> }{" "}
                    </div>
                    <div className="searchbar-option-spot-name">
                      <p>
                      {spot.name} 
                      </p>
                      <p 
                        className="searchbar-option-spot-city"
                        style={{
                          color: "#bbb",
                          fontWeight: 400,
                          marginLeft: '1ch',
                        }}
                      >
                        <span>/ </span>{city}
                      </p>
                    </div>

                  </Link>
                ))}
              </>
            )
          })
          )
        }
      </div>
    </div>
  )
}

const SearchBarComponent = (props) => {
  const { user, countryCode, searchbarList } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [spotList, setSpotList] = useState({});
  const [searchResult, setSearchResult] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [notFoundAnySpot, setNotFoundAnySpot] = useState(false);
  const [mainCountry, setMainCountry] = useState('UY');

  useEffect(() => {
    if(searchbarList != null)
      setSpotList(searchbarList);    
    else 
      ( async () => {
        let spotRespose = await spotService.getSpots("", true);
        spotRespose = formatData(spotRespose);
        setSpotList(spotRespose);
        store.dispatch({
          type: 'SEARCHBAR_LIST',
          data: spotRespose,
        })
      })();

  }, []);

  const isEmpty = (obj) => {
    if(obj != null)
      return Object.keys(obj).length < 1;

    return true;
  }

  const handleMainCountry = () => {
    let topCountry = 'UY'; 
    
    if(countryCode != null && constant['AVAILABLE_COUNTRIES'][countryCode]) 
      topCountry = countryCode;
    else if(user.country != null && constant['AVAILABLE_COUNTRIES'][user.country]) 
      topCountry = user.country;

    setMainCountry(topCountry);
    return topCountry;
  }
  
  const formatData = (data) => {
    let res = {};
    let countries = Object.keys(data).sort((a, b) => a.localeCompare(b));
    let topCountry = handleMainCountry()
    
    countries = [constant['AVAILABLE_COUNTRIES'][topCountry] , ...countries.filter(country => country != constant['AVAILABLE_COUNTRIES'][topCountry])];

    countries.map((country) => {
      res[country] = {};
      Object.keys(data[country]['all']).sort((a, b) => a.localeCompare(b)).map( city => {
        let spots = data[country]['all'][city]['values'];
        res[country][city] = spots.sort((a, b) => a.name.localeCompare(b.name))
      });
    })
    return res;
  }

  function normalizeString(str) {
    return str
      .normalize("NFD") // Descompone los caracteres especiales en caracteres básicos y marcas de acento
      .replace(/[\u0300-\u036f]/g, "") // Elimina las marcas de acento
      .toLowerCase(); // Convierte a minúsculas
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let query = searchTerm.trim();
      let data = {};
      let notFound = false;
      if (query !== "" && query.length >= 2) {
        data = searchInSpots(query);
        notFound = isEmpty(data);
      }
      setSearchResult(data);
      setNotFoundAnySpot(notFound);

    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchInSpots = (text) => {
    text = normalizeString(text);
    let result = {};
    handleMainCountry();
    if(!isEmpty(spotList)){

      Object.keys(spotList).map( country => {
        result[country] = {};
        const normalizedCountry = normalizeString(country);
        if(normalizedCountry === text){ 
          result[country] = spotList[country];
          setMainCountry(constant['ABBREVIATIONS'][country]);
          return;
        }

        Object.keys(spotList[country]).map( city => {
          const normalizedCity = normalizeString(city);
          if(normalizedCity === text){ 
            result[country][city] = spotList[country][city];
            setMainCountry(constant['ABBREVIATIONS'][country]);
            return;
          }

          result[country][city] = [];
          spotList[country][city].map( spot => {
            let { name } = spot;
            const normalizedName = normalizeString(name);
            if(normalizedName.includes(text)){
              result[country][city].push(spot);
            }
          })
          if(result[country][city].length <= 0) delete result[country][city];
        })
        if(isEmpty(result[country])) delete result[country] ;
      })
    } 

    return result;
  };

  const handleOpen = () => {
    utils.toggleScrollBar(!open);
    setSearchTerm('');
    setOpen(!open);
  }

  return (
    <>
      { open &&
      <div className="searchbar-screen-container">
        <div>
          <div className="searchbar-container">
            <InputGroup style={{flexWrap: "nowrap"}}>
            <Input
                className="searchbar-input"
                type="text"
                autoFocus={true}
                value={searchTerm}
                placeholder={t("global_search_placeholder")}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <InputGroupText style={{
                  background: 'rgb(0, 73, 137)',
                  border: '1px solid rgb(0, 73, 137)'
                }}>
                <AiOutlineSearch style={{
                  width: '23px',
                  height: '23px',
                  color: '#fff',
                }}/>
              </InputGroupText>
            </InputGroup>
            <button 
              className="searchbar-close-button"
              onClick={() => handleOpen()}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="searchbar-list-container">
            {!isEmpty(searchResult) &&
              <>
                {Object.keys(searchResult).map( country => {
                  return (
                    <>
                      <Option country={country} cities={searchResult[country]} selectMethod={handleOpen} mainCountry={mainCountry}/>
                    </>
                  );

                })}  
              </>
            }

            {notFoundAnySpot && <div className="searchbar-not-found-any-spot">{t("global_search_empty_list")}</div>}
          </div>

        </div>
        <div onClick={() => handleOpen()} style={{width: '100%', height: '100%', background: '#fff', opacity: '.75'}}></div>

        </div>
      }
      

      <div className="container-xl">
        <SearchBarContainer onClick={() => handleOpen()}>
          <InputGroup style={{flexWrap: "nowrap"}}>
            <Input
              type="text"
              autoFocus={false}
              placeholder={t("global_search_placeholder")}
              className={props.status !== "logged" ? "bar-reduce" : ""}
            />
            <InputGroupText>
              <AiOutlineSearch />
            </InputGroupText>
          </InputGroup>
        </SearchBarContainer>
      </div>
    </>
  );
};

const SearchBarContainer = styled.div`
  width: 100%;
  padding-left: 0px;
  margin-left: 30px;

  @media (max-width: 1000px) {
    margin-left: 0px !important;
  }

  input {
    width: 265px !important;
    background-color: #ffffff !important;
    color: #355482;
    border: 1px solid #355482 !important;

    @media (max-width: 1000px) {
      width: 100px !important;
    }
  }

  .bar-reduce {
    width: 200px !important;

    @media (max-width: 1000px) {
      width: 100px !important;
    }
  }

  .input-group-text {
    padding: 0 0.5rem !important;
    background-color: #004989;
    border: 1px solid #004989;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  input:focus {
    color: #355482;
  }

  input::placeholder {
    color: #35548294;
    font-size: 0.8em;
    font-family: "poppins";
  }

  svg {
    width: 23px;
    height: 23px;
    z-index: 99;
    color: #ffffff;
  }

  li {
    margin-bottom: 5px;
    cursor: pointer;
  }

  .icon-cam {
    width: 14px;
    margin-left: 0px;
    margin-bottom: 3px;
  }

  .spot-detail {
    font-size: 0.8em;
  }

  .spot-name {
    font-family: "poppins";
    font-size: 0.9em;
  }

  .spinner-border {
    width: 1rem !important;
    height: 1rem !important;
    padding: 10px;
    color: #004989;
    margin-left: 40% !important;
  }

  .spot-error {
    font-size: 0.8em;
    font-family: "poppins";
  }

  a {
    color: #355482 !important;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  countryCode: state.country,
  searchbarList: state.searchbarList,
});

export default connect(mapStateToProps)(SearchBarComponent);
