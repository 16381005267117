import React from "react";
import StarRating from "react-svg-star-rating";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import utils from "../utils/utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWind, faWater } from '@fortawesome/free-solid-svg-icons';

const SpotCard = (props) => {
  const { name, id, img, url, multicam, position_camera, forecast, surfcam, is_premium, country, city } = props.spot;
  const { currentTime } = props;
  const spotIdsLakes = ["1001", "3600", "1016", "1220", "404", "1200","1000","1002","1210"];
  const history = useHistory();

  return (
    <Link to={utils.getSpotUrl(country, city, url) + (multicam === true ? `/${position_camera}` : "")} className="spot-card-items-container" style={{ cursor: "pointer" }}>
      <div className=" spot-card-items">
        {img && <img src={img} alt={""} />}
        {surfcam && (
          <div className="icon-cam-container" style={{ backgroundColor: is_premium ? null : "#2b5283" }}>
            <img style={{ height: "23px" }} crossOrigin="anonymous" className={`icon-cam absolute`} src={require("../assets/icons/cam_white.svg")} alt="cam-icon" />
          </div>
        )}{" "}
        <div id={"gtm-list-spots-" + id} className="spotContent">
          <span >{name}</span>
          <StarRating unit="half" size={15} count={5} initialRating={forecast[currentTime].rating} edit={false} isReadOnly={true} color2="#dddddd" activeColor="#FABC41" />
        </div>
      </div>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  currentTime: state.currentTime,
});

export default connect(mapStateToProps)(SpotCard);
