import utils from "../utils/utils";
import axios from "axios";
import instance from "./service";
import constant from "../utils/constant";
import auth from "../modules/auth";
import { iso31661 } from "iso-3166";

class Payment {
  async subscribe(cardToken, planId, name, country, region, city, address, discountCode, userDocument, referrerId, affiliate) {
    let result = await instance.post(
      "/premium/subscribe",
      { plan_id: planId, coupon_id: discountCode, referrerId: referrerId, document: userDocument, billing_info: { name: name, country: country, region: region, city: city, addres: address }, card: { token: cardToken }, affiliate },
      {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      },
    );
    if (result && result.code === 200) {
      await auth.getUser();
    }
    return result;
  }

  async refillMinutes(cardToken, planId, name, country, region, city, address, discountCode, userDocument) {
    let result = await instance.post(
      "/user/refillMinutes",
      { plan_id: planId, coupon_id: discountCode, document: userDocument, billing_info: { name: name, country: country, region: region, city: city, addres: address }, card: { token: cardToken } },
      {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      },
    );
    if (result && result.code === 200) {
      await auth.getUser();
    }
    return result;
  }

  async cancelSubscription(planId, reasonId, reasonText) {
    let result = await instance.post(
      "/premium/unsubscribe",
      { reason_id: reasonId, reason_text: reasonText },
      {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      },
    );
    if (result && result.code === 200) {
      await auth.getUser();
    }
    return result;
  }

  async monthToAnual() {
    let result = await instance.post(
      "/premium/moveToAnually",
      {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      },
    );
    await auth.getUser();
  }

  async visitedCheckout() {
    try {
      await instance.post("/premium/visitedCheckout", {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH)
      });
      return true;
    } catch (error) {
      console.log('Error at payment.visitedCheckout: ' + error);
      return false;
    }
  }

  async getPaymentStatus(userId){
    try{
      let status = await instance.post("/getPaymentStatus",
      {
        id: userId,
      },
      {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      });
      return status.alert
    }
    catch (error) {
      console.log(`getPaymentStatus error: ${error}`);
      return false;
    }
  }

  getCountryCode(countryName) {
    const countryInfo = iso31661.find((x) => x.name === countryName);
    return countryInfo["alpha2"];
  }
}

const payment = new Payment();

export default payment;
