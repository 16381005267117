import store from "../utils/store";

class CountryReducer {

  saveCountry(country) {
    store.dispatch({
      type: "COUNTRY",
      data: country,
    });
  }
}

const countryReducer = new CountryReducer();

export default countryReducer;