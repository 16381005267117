import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import LoaderComponent from "../components/LoaderComponent";
import session from "../modules/session";
import CheckoutView from "../views/checkoutView";
import PremiumLandingView from "../views/premiumLandingView";
import SpotView from "../views/spotView/spotView";
import Spots from "./../views/spots/spots";
import DirectRoute from "./routes/DirectRoute";
import PremiumRoutes from "./routes/PremiumRoutes.js";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PublicRoute from "./routes/PublicRoute.js";
import affiliateService from "../modules/AffiliateService.js";

const AccessView = lazy(() => import("../views/accessView.js"));
const LandingView = lazy(() => import("../views/landing/landingView"));
const HomeView = lazy(() => import("../views/home/homeView"));
const Terms = lazy(() => import("../views/policy/terms.js"));
const Policy = lazy(() => import("../views/policy/policy.js"));
const MultiSpotView = lazy(() => import("../views/multiSpotView/multiSpotView"));
const NotificationsView = lazy(() => import("../views/notificationsView/notificationsView.js"));
const ProfileView = lazy(() => import("../views/profileView/profileView.js"));
const WelcomeView = lazy(() => import("../views/welcomeView/welcomeView"));
const ConfirmEmailView = lazy(() => import("../views/confirmEmailView"));
const PremiumSuccessVIew = lazy(() => import("../views/premiumView/premiumSuccessVIew.js"));
const NotFound404 = lazy(() => import("../views/notFound404/notFound404"));
const EmailView = lazy(() => import("../components/emailWall"));
const ValidateBenefitView = lazy(() => import("../components/validateBenefit"));
const ActivateGiftCardView = lazy(() => import("../components/activateGiftCard"));
const MoreOptionsMenu = lazy(() => import("../views/moreOptionsView/moreOptionsMenu.js"));
const CamDashboard = lazy(() => import("../components/camDashboard.js"));

const AppRoutes = (props) => {

  const checkAffiliate = () => {
    const affilate = affiliateService.getUrlAffiliate();
    if (affilate) {
      const checkExistingAffiliate = affiliateService.getAffiliate();
      if (!checkExistingAffiliate || checkExistingAffiliate === affilate) {
        affiliateService.addAffiliate(affilate);
      }
    }
  }

  const checkCookie = async () => {
    if (props && props.user === undefined) {
      await session.checkCookie();
    }
  };

  useEffect(() => {
    checkCookie();
    checkAffiliate();
  }, []);


  return (
    <>
      <Router basename="/">
        {props.cookie ? (
          <Suspense fallback={<LoaderComponent />}>
            <Switch>
              <DirectRoute exact path="/" component={LandingView} />
              <DirectRoute exact path="/validate-benefit" component={ValidateBenefitView} />
              <DirectRoute exact path="/activate-giftcard" component={ActivateGiftCardView} />              
              <DirectRoute exact path="/access" component={AccessView} />
              <DirectRoute exact path="/confirmation-email" component={ConfirmEmailView} />
              <DirectRoute exact path="/plans/minutes/checkout" component={CheckoutView} />
              <DirectRoute exact path="/premium/checkout" component={CheckoutView} />
              <DirectRoute exact path="/premium" component={PremiumLandingView} />
              {/* <DirectRoute exact path="/plans/minutes" component={PlansMintuesView} /> */}
              <ProtectedRoutes exact path="/home" component={HomeView} />
              <ProtectedRoutes exact emailValidate={false} path="/email" component={EmailView} />
              <ProtectedRoutes exact path="/notifications" component={NotificationsView} />
              <ProtectedRoutes exact path="/favorites" component={MultiSpotView} />
              <ProtectedRoutes exact path="/favorites/list" component={MultiSpotView} />
              <ProtectedRoutes exact path="/favorites/all" component={MultiSpotView} />
              <PublicRoute exact path="/reset-password" component={HomeView} />
              <PublicRoute exact path="/privacy-policy" component={Policy} />
              <PublicRoute exact path="/terms-of-use" component={Terms} />
              <ProtectedRoutes exact path="/welcome" emailValidate={false} component={WelcomeView} />
              {/* <ProtectedRoutes exact path="/plans/minutes/checkout/success" component={PlansMintuesViewSuccess} /> */}
              <ProtectedRoutes exact path="/premium/checkout/success" component={PremiumSuccessVIew} />
              <ProtectedRoutes exact path="/premium/welcome" component={PremiumSuccessVIew} />
              <ProtectedRoutes exact path="/account" component={ProfileView} />
              <ProtectedRoutes exact path="/account/subscription" component={ProfileView} />
              <ProtectedRoutes exact path="/account/preferences" emailValidate={false} component={ProfileView} />
              <ProtectedRoutes exact path="/account/history" component={ProfileView} />
              <ProtectedRoutes exact path="/account/wave-alerts" component={ProfileView} />
              <ProtectedRoutes exact path="/account/referrals" component={ProfileView} />
              <ProtectedRoutes exact path="/account/benefits" component={ProfileView} />
              <ProtectedRoutes exact path="/account/profile" component={ProfileView} />
              <ProtectedRoutes exact path="/options" component={MoreOptionsMenu} />
              <PublicRoute exact path="/account/cancel-subscription" component={ProfileView} />
              <PublicRoute exact path="/spots" component={Spots} />
              <PublicRoute exact path="/spots/" component={Spots} />
              <PublicRoute exact path="/spots/country/:countryParam/:orientationParam" component={Spots} />
              <PublicRoute exact path="/spots/:spotId" component={SpotView} />
              <PublicRoute exact path="/spots/:spotId/:cameraPosition" component={SpotView} />
              <PublicRoute exact path="/spots/:countryName/:cityName/:spotId" component={SpotView} />
              <PublicRoute exact path="/spots/:countryName/:cityName/:spotId/:cameraPosition" component={SpotView} />
              <PremiumRoutes exact path="/spots/:spotId/camrewind" component={SpotView} isCamRewind={true} />
              <PremiumRoutes exact path="/spots/:spotId/:cameraPosition/camrewind" component={SpotView} isCamRewind={true} />
              <PremiumRoutes exact path="/spots/:countryName/:cityName/:spotId/camrewind" component={SpotView} isCamRewind={true} />
              <PremiumRoutes exact path="/spots/:countryName/:cityName/:spotId/:cameraPosition/camrewind" component={SpotView} isCamRewind={true} />
              <ProtectedRoutes exact path="/camDashboard" component={CamDashboard} />
              <DirectRoute path="*" component={NotFound404} />
            </Switch>
          </Suspense>
        ) : (
          <LoaderComponent />
        )}
      </Router>
    </>
  );
};

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  cookie: state.cookie,
});

export default connect(mapStateToProps)(AppRoutes);
