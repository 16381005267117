import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const ForecastLimitedPlan = (props) => {
  const { t } = useTranslation();

  //Properties (title,description)
  return (
    <>
      {props.status === "logged" && props.user && props.user.isPremium === false && Object.keys(props.existData).length > 0 && (
        <ForecastRegisterContainer>
          <ForecastRegisterTitle>{t("forecast_limited_modal_title")}</ForecastRegisterTitle>
          <ForecastRegisterDescription>
            <Trans
              i18nKey="forecast_limited_modal_description" // optional -> fallbacks to defaults if not provided
              values={{ spotName: props.spotName }}
              components={{ italic: <i />, bold: <strong /> }}
            />
          </ForecastRegisterDescription>
          <StreamOverlayButtonBox>
            <Link to={{ pathname: "/premium", state: { prevPath: window.location.pathname, isRegister: true } }}>{t(props.user.canUseFreeTrial === true ? "forecast_limited_register_button_free_trial" :  "forecast_limited_register_button")}</Link>
          </StreamOverlayButtonBox>
        </ForecastRegisterContainer>
      )}
    </>
  );
};

const ForecastRegisterTitle = styled.h1`
  text-align: center;
  font-size: 1.7em;
  font-weight: bold;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: #000;
  @media (max-width: 1000px) {
    font-size: 1.5em;
  }
`;

const ForecastRegisterDescription = styled.p`
  vertical-align: middle;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  width: 64%;
  font-size: 1.3em;
  @media (max-width: 1000px) {
    font-size: 1.1em !important;
    width: 100%;
  }
`;

const ForecastRegisterContainer = styled.section`
  text-align: center;
  padding: 50px;
  position: absolute;
  z-index: 99;
  background-color: #ffffff8c;
  -webkit-box-shadow: 5px 6px 50px 38px rgb(255 255 255 / 70%);
  box-shadow: 5px 6px 50px 38px rgb(255 255 255 / 70%);
  margin-top: 40%;

  @media (max-width: 1000px) {
    padding: 20px !important;
    margin-left: 300%;
  }

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  margin-left: 150%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StreamOverlayButtonBox = styled.div`
  vertical-align: middle;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 0;
  margin-top: 30px;
  margin-left: 0;
  margin-right: 0;

  @media (max-width: 1000px) {
    display: grid;
    padding: 0 20px;
    margin-top: 10px !important;
    a {
      padding: 8px 25px !important;
      font-size: 0.9em;
    }

    a:nth-child(1) {
      margin-bottom: 5px !important;
    }

    a:nth-child(2) {
      margin-top: 5px !important;
    }
  }

  a:nth-child(1) {
    padding: 10px 25px;
    background-color: #32c943;
    border-radius: 5px;
    color: #fff;
    margin: 10px;
  }

  a:nth-child(2) {
    padding: 10px 25px;
    background-color: #425781;
    border-radius: 5px;
    color: #ffffffeb;
    margin: 10px;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  surfcams: state.surfcams,
});

export default connect(mapStateToProps)(React.memo(ForecastLimitedPlan));
