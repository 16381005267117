import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import ReactPlayer from "react-player";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import constant from "../utils/constant";


const AccessWallComponent = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.openModal);
  }, [props.openModal]);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      props.setIsWallOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      {isOpen === true && (
        <PopUpContainer>
          <Fade>
            <PopUpBox>
              <AiOutlineClose className="close-icon" onClick={() => props.setIsWallOpen(false)} />
              <WallpaperContainer>
                {props.feature === "camera_premium" ?
                <ReactPlayer url={"https://images-store.lineup.surf/videos/" + props.feature + ".mp4"} playsinline={true} playing={true} controls={true} loop muted={true} width="100%" height="100%" pause="true" /> : 
                <img src="https://images-store.lineup.surf/surfbreak/24.webp"/>
                }
              </WallpaperContainer>
              {/*  */}
              
              <PopUpTitle>{t("access_wall_title_" + props.feature)}</PopUpTitle>
              <PopUpDescription>
                <Trans
                  i18nKey={"access_wall_description_" + props.feature} // optional -> fallbacks to defaults if not provided
                  values={{ spotName: props.spotName }}
                  components={{ italic: <i />, bold: <strong /> }}
                />
              </PopUpDescription>
              { ((props.disableButton !== true) && (props.feature != constant.FEATURE_MULTISPOT_TOP)) && (
                <PopUpButtonContainer>
                  <PopUpButton>
                    <Link to="/premium" onClick={() => props.setIsWallOpen(false)}>
                      {props.user && props.user.canUseFreeTrial === true ? t("access_wall_button_free_" + props.feature) : t("access_wall_button_" + props.feature)}
                    </Link>
                  </PopUpButton>
                </PopUpButtonContainer>
              )}
            </PopUpBox>
          </Fade>
        </PopUpContainer>
      )}
    </>
  );
};

const WallpaperContainer = styled.div`
  border-radius: 3px !important;
  img {
    max-height: 300px !important;
    width: 100% !important;
    border-radius: 3px !important;
  }
`;

const PopUpButton = styled.li`
  width: 100% important;
  list-style: none !important;
  list-style-type: none !important;
  a {
    display: block;
    height: 60px !important;
    line-height: 60px !important;
    color: #fff;
    transition: background-color 0.2s;
    background-color: #31c241;
  }
`;

const PopUpButtonContainer = styled.ul`
  padding: 0 !important;
  color: #fff;
  margin-bottom: 0px;

  li:first-child button {
    background: #31c241;
    border: none;
    width: 100%;
    border-radius: 0 0 0.25em 0.25em;
  }
`;

const PopUpTitle = styled.h1`
  font-size: 1.3em;
  font-weight: 500;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 0px;
  font-family: "Poppins";

  @media (max-width: 1000px) {
    font-size: 1em;
    font-weight: 600;
  }
`;

const PopUpDescription = styled.p`
  font-size: 0.9em !important;
  font-weight: 400 !important;
  padding: 10px 30px !important;
  font-family: "Poppins";

  @media (max-width: 1000px) {
    padding: 10px 30px !important;
  }
`;

const PopUpContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;
  z-index: 999999999999999 !important;
  video {
    border-radius: 3px !important;
  }
`;

const PopUpBox = styled.div`
  position: relative;
  width: 90%;
  max-width: 600px;
  margin: 4em auto;
  background: #fff;
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  text-align: center;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-backface-visibility: hidden;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  top: 12%;
  transform: translateY(0);

  svg {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    background-color: #000;
    border-radius: 50%;
    color: #fff;
    padding: 5px;
    cursor: pointer;
    z-index: 999;
  }

  @media (max-width: 1000px) {
    max-width: 400px;
    top: -5% !important;
  }
`;

export default AccessWallComponent;
