import store from "../utils/store";

class CurrentTimeReducer {

    updateCurrentTime(){

        let hours = new Date().getHours();
        let currentTime = (hours < 11 ? 'morning' : hours < 17 ? 'midday' : 'afternoon');

        store.dispatch({
            type: "CURRENT_TIME",
            data: currentTime
        })
    }
}

const currentTimeReducer = new CurrentTimeReducer();

export default currentTimeReducer;