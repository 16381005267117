import instance from "./service";
import store from "../utils/store";

class Plans {
  async getPlans(country) {
    const planList = {};
    let result = await instance.get("/premium/getPlans", { params: { country } });

    // transform the index array to literal object
    for (let plan of result) {
      let id = plan.id
      planList[id] = plan;
    }
    return planList;
  }

  async getPlan(planId, country) {
    const currentState = store.getState();
    let result = await instance.get("/premium/getPlan", { params: { plan_id: planId, country } });
    return result;
  }
}

const plans = new Plans();

export default plans;
