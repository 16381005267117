import store from "../utils/store";

class LanguageReducer {

  saveLan(lan) {
    store.dispatch({
      type: "LANGUAGE",
      data: lan,
    });
  }
}

const languageReducer = new LanguageReducer();

export default languageReducer;