import TagManager from 'react-gtm-module'

class GtmService {

    async sendEvent(event) {
        TagManager.dataLayer({
            dataLayer: {
                event: event
            },
        });
    }
}

const gtmService = new GtmService();

export default gtmService;




