import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import Truncate from "react-truncate";
import { useTranslation } from "react-i18next";

const ReadMore = ({ children, lines }) => {
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);
  const { t } = useTranslation();

  const handleTruncate = useCallback((truncated) => {
    if (truncated !== truncated) {
      setTruncated(truncated);
    }
  }, []);

  const toggleLines = useCallback(
    (event) => {
      event.preventDefault();
      setExpanded(!expanded);
    },
    [expanded],
  );

  return (
    <div>
      <Truncate
        lines={!expanded && lines}
        ellipsis={
          <span>
            ...{" "}
            <a style={{ display: "inline", backgroundColor: "white", color: "#007bff", border: "none", cursor: "pointer",
                        textDecoration: "underline", fontWeight: "bold" }} href="#" onClick={toggleLines}>
              {t("_VIEW_MORE")}
            </a>
          </span>
        }
        onTruncate={handleTruncate}>
        <ReactMarkdown>{children}</ReactMarkdown>
      </Truncate>
      {!truncated && expanded && (
        <span>
          {" "}
          <a style={{ display: "inline", backgroundColor: "white", color: "#007bff", border: "none", cursor: "pointer",
                      textDecoration: "underline", fontWeight: "bold" }} href="#" onClick={toggleLines}>
            {t("_VIEW_LESS")}
          </a>
        </span>
      )}
    </div>
  );
};

ReadMore.defaultProps = {
  lines: 3,
};

ReadMore.propTypes = {
  children: PropTypes.node,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
};

export default ReadMore;
