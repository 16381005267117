import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

const LoaderComponent = (props) => {
  return (
    <LoaderBox>
      <LoaderShadow />
      <img crossOrigin="anonymous"alt="..." src={require("../assets/logo/iso-logo.svg")} className="logo-loader" />
    </LoaderBox>
  );
};

const LoaderBox = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  top: 0;
  left: 0;

  img {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100px;
  }
`;

const LoaderShadow = styled.div`

  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  height: 100px;
  width: 100px;
  background: #fff;
  margin: auto;
  -webkit-animation: webkit-lineup-load 2s forwards infinite; 
  -moz-animation: moz-lineup-load 2s forwards infinite; 
  -o-animation: o-lineup-load 2s forwards infinite; 
  animation: lineup-load 2s forwards infinite; 
  z-index: 9999999;

  @keyframes lineup-load {
    0% {
      height: 7%;
    }

    100% {
      height: 0%;
    }
  }

  @-webkit-keyframes webkit-lineup-load {
    0% {
        height: 7%;
    }

    100% {
      height: 0%;
    }
  }

  @-moz-keyframes moz-lineup-load {
    0% {
        height: 7%;
    }

    100% {
      height: 0%;
    }
  }

  
  @-o-keyframes o-lineup-load {
    0% {
        height: 7%;
    }

    100% {
      height: 0%;
    }
  }

`;

export default LoaderComponent;
