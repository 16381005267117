import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import utils from "../utils/utils";
import constant from "../utils/constant";
import { useTranslation } from "react-i18next";
import { useCountdown } from '../hooks/useCountdown';
import ShowCounter from "./showCounter";

const AddComponent = (props) => {
  const { t } = useTranslation();
  const endDate = '2022-11-27T23:59:59';
  const [isMonth, setIsMonth] = useState(false);
  const [amountToPay, setAmountToPay] = useState("0.00");
  const [blockedPlan, setBlockedPlan] = useState(false);
  const [days, hours, minutes, seconds] = useCountdown(new Date(endDate));

  useEffect(() => {
    verifyPlan(isMonth);
  }, [isMonth]);

  useEffect(() => { calculateAmountToPay(); });

  const selectedPlan = () => {
    props.selectedPlan(props.monthlyPlan);
  }

  const handlePlan = (needChange) => {
    const changePlan = !isMonth;
    verifyPlan(changePlan);
    setIsMonth(changePlan);
    if (props.isPremiumPlan) {
      props.setIsMonthPlan(changePlan)
    }
  }

  const verifyPlan = (isMonthActive) => {
    if (props.user) {
      if (((props.user.nextPlanId === constant.SUBSCRIPTION.ESPECIAL_DAY)) || (props.user.currentPlanId === constant.SUBSCRIPTION.ESPECIAL_DAY)) {
        setBlockedPlan(true)
      }
    }
  }

  const calculateAmountToPay = () => {
    const planAmount = utils.calculateAmountToPay(props.monthlyPlan.amount, props.discount);
    setAmountToPay(planAmount);
  }

  const isValidDay = () => {
    const currentDay = new Date();
    const endDay = new Date(endDate);
    return currentDay < endDay;
  }

  return (
    <Card className={"espcial-day-" + props.especialdayIndex} style={{display: isValidDay() === false ? "none" : "inital"}}>
      <h1><span>{t("premium_landing_view_especial_day_title")}</span></h1>
      <h3>{t("_CURRENCY")} {utils.roundToTwo(amountToPay / 6)} <TypePrice> / {t("premium_landing_view_per_month")}</TypePrice></h3>
      <AnualLabelPrice>{t("premium_landing_view_especial_day_description")}</AnualLabelPrice>
      <NoPaymentText>{t("premium_landing_view_especial_day_discount")}</NoPaymentText>
      <p>{t("premium_landing_view_premium_card")}</p>
      <button disabled={blockedPlan} onClick={() => selectedPlan()}>
        {blockedPlan === true ? t("premium_landing_view_current_plan") :
          props.user.currentPlanId ? props.user.isPremium == true ? t("premium_landing_view_update_plan") : t("premium_landing_view_get_premium") : t("premium_landing_view_join_now")}
      </button>
      <CounterDownText>{t("premium_landing_view_especial_day_counter")}</CounterDownText>
      <CounterDown className="switch-wrap d-flex justify-content-center" id="BillingType">
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      </CounterDown>

    </Card>
  );
};

const CounterDownText = styled.h6`
    margin-top: 86px;
    text-align: center;
    color: #fff;
    font-size: 0.8em;
    margin-bottom: 10px;
    margin-left: -2px;

    @media (max-width: 1000px) {
      margin-top: 18px;

      }
 
`

const CounterDown = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -11px;
    
    
.expired-notice {
  text-align: center;
  padding: 2rem;
  border-radius: 0.25rem;
  margin: 0.5rem;
  
}

.expired-notice > span {
  font-size: 9px;
  font-weight: bold;
  color: #fff;
  
}

.expired-notice > p {
  font-size: 9px;
  color: #fff !important;
}

p{
  height: 21px !important;

}

.show-counter {
  padding: 0.5rem;
  color: #fff;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #fff;
}


.show-counter .countdown {
  line-height: 1.25rem;
  padding: 2px 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
}


.show-counter .countdown > p {
  margin: 0;
  height: 22px !important;
  font-size: 1.2rem;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.7rem;
  line-height: 1rem;
  font-size: 7px;
}
`


const CardTag = styled.span`
  font-size: 0.7em !important;
  font-weight: 500 !important;
  `

const AnualLabelPrice = styled.p`
  font-size: 0.7em !important;
  margin-top: -8px !important;
  margin-bottom: 10px !important;
  color: #203657;
  height: auto !important;
  font-weight: 700;
  margin-left: 2px;
  
  `

const Card = styled.div`
    padding: 20px;
    margin: 0px 10px;
    border: 1px solid #0b0b0b;
    background-color: #0b0b0b;
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    border-radius: 16px;
    width: 300px;
    height: 385px;
    margin-top: 20px;
    position: relative;
    color: #fff;

    button{
      border: none;
      padding: 12px 20px;
      border-radius: 5px;
      background-color: #fbbd40;
      color: #000;    
      font-weight: 800;
      font-size: 0.7em;
      position: absolute;
      bottom: 85px;
      margin-right: 20px;
      width: 100%;
      width: -moz-available;          /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      width: fill-available;

      @media (max-width: 1000px) {
        position: initial;
        bottom: 0px;
        margin-right: 20px;
        width: 100%;
      }
    }
    button:disabled, button[disabled] {
    background-color: #d9dfe6 !important;
    box-shadow: none;
    color: #8da2c0 !important;
}

    h1{
      font-size: 1.2em;
      font-weight: 800;
      margin: 10px 0px;
      color: #fff;
    }

    h3{
      font-size: 2em;
      font-weight: 700;
      margin: 12px 0px;
      margin-bottom: 8px;
      color: #fff;
    }

    p{
      font-size: 0.8em;
      margin-bottom: 25px;
      height: 55px;
      color: #fff;

    }

    .not-apply-free-trial{
      color: #99a094 !important;
      text-decoration: line-through !important;
    }

    .anual-description{
      position: absolute;
      font-size: 0.8em;
      margin-top: -22px;
      font-weight: 500;
      color: #fff;

    }

    .margin-anually{
      margin-bottom: 40px;
    }

`;

const MostPopular = styled.span`
    background-color: #000;
    font-size: 0.5em !important;
    color: #fff;
    padding: 5px 10px;
    border-radius: 100px;
    position: absolute;
    margin-left: 80px !important;
    margin-top: 1px;
`;

const NoPaymentText = styled.h5`
    margin-bottom: 20px;
    color: #fff;
    font-weight: 600;
    font-size: 1em;
`;

const FreeTrialText = styled.h5`
     margin-bottom: 20px;
    color: #55972E;
    font-weight: 600;
    font-size: 1em;
`;

const TypePrice = styled.span`
    font-size: 0.4em;
    font-weight: 600;
    margin-left: 0px;
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
});

export default connect(mapStateToProps)(React.memo(AddComponent));
