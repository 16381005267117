import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import auth from "../modules/auth";
import { Spinner } from "reactstrap";
import constant from "../utils/constant";
import { Link } from "react-router-dom";

const ToastExpiringPremium = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    if (props.user.isPremium === true && props.user.nextPlanId === constant.SUBSCRIPTION.FREE) {
      let currentDate = new Date();
      let endDate = new Date(props.user.endCurrentPlan);
      let difference = endDate.getTime() - currentDate.getTime();
      let days = Math.ceil(difference / (1000 * 3600 * 24));
      if (days <= 7 && days >= 0) {
        setTotalDays(days);
        setShow(true);
      }
    }
  });

  return (
    <>
      {show === true && (
        <ToastContainer
          style={{
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
            marginBottom: props.marginBottom,
            backgroundColor: props.backgroundColor,
            marginTop: props.marginTop,
            fontSize: props.fontSize,
            color: props.fontColor ? props.fontColor : "#4a4a4a",
          }}>
          <Trans i18nKey="toast_expiring_premium_text_1" values={{ days: totalDays }} components={{ bold: <strong /> }} />{" "}
          <span className="bold"> <Trans i18nKey={totalDays === 1 ? "_DAY" : "_DAYS"} components={{ bold: <strong /> }} /></span>{" "}
          <Trans i18nKey="toast_expiring_premium_text_2" components={{ bold: <strong /> }} />{" "}
          <Link to="/premium"><Trans i18nKey="toast_expiring_premium_call_action" components={{ bold: <strong /> }} /></Link>
        </ToastContainer>
      )}
    </>
  );
};

const ToastContainer = styled.p`
  text-align: center;
  padding: 10px !important;
  border-radius: 3px;
  font-family: "Poppins";

  @media (max-width: 1000px) {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }

  .spinner-border {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  a{
    font-weight: bold;
    color: #000;
    text-decoration: underline;
  }

`;

const ResendConfirmationEmailButton = styled.button`
  border: none;
  background-color: transparent;
  font-style: italic;
  color: #004989;
  font-weight: 600;
  font-size: 0.8em;
  cursor: pointer;
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
});

export default connect(mapStateToProps)(ToastExpiringPremium);
