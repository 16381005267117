import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import store from "../../utils/store";
import NavbarComponent from "../../components/navbarComponent";
import utils from "../utils";
import constant from "../constant";

const PremiumRoutes = (props) => {
  const { redirectPath, component, user, loggedIn, ...routeProps } = props;
  const Component = component;
  const isAccessible = Boolean(user) && user.isPremium;
  const isSpecialDay = Boolean(utils.isValidDate(constant.VALIDATE_DAY.camerewind_start, constant.VALIDATE_DAY.camerewind_end))
  const isLogged = Boolean(user) && loggedIn;

  return (
    <>
      <NavbarComponent />
      <Route
        {...routeProps}
        render={(props) => {
          if (!isLogged) {
            store.dispatch({
              type: "OPEN_LOGIN",
              data: {
                open: true,
                isRegister: false,
                url: "/premium",
              },
            });
            return <Redirect to={{ pathname: redirectPath || "/premium" }} />;
          }
          if (isAccessible || isSpecialDay) return <Component {...props} />;
          return <Redirect to={{ pathname: redirectPath || "/premium" }} />;
        }}
      />
    </>
  );
};

PremiumRoutes.propTypes = {
  path: PropTypes.string.isRequired,
  redirectPath: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.shape({ render: PropTypes.func.isRequired }), PropTypes.func]),
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.status,
    user: state.user,
  };
};

export default connect(mapStateToProps)(PremiumRoutes);
