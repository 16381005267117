import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import utils from "../../utils/utils";
import CamRewindComponent from "../../components/camRewindComponent";
import "./spot.scss";
import LoaderComponent from "../../components/LoaderComponent";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import spotService from "../../modules/spotService";
import SpotComponent from "../../components/spotComponent";
import currentTimeReducer from "../../reducers/currentTimeReducer";

const SpotView = (props) => {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  let { countryName, cityName, spotId, cameraPosition } = useParams();
  const [spotPremium, setspotPremium] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [forcastDays, setForcastDays] = useState([]);
  const [currentDayForecast, setCurrentDayForecast] = useState({});
  const [isCamRewind, setIsCamRewind] = useState(false);
  const [allCams, setAllCams] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(0);

  useEffect(() => {
    if(props.currentTime === undefined){ 
      currentTimeReducer.updateCurrentTime() 
    }
    getCamInfo(spotId, cameraPosition);
  }, [spotId, cameraPosition]);

  const getCamInfo = async (spotId) => {
    setIsLoading(true);
    setIsCamRewind(false);
    let data = await spotService.getSpotByUrl(spotId);
    if (data && data.length > 0) {
      data = selectCamMultiCam(data, cameraPosition);
    } else {
      history.push({
        pathname: "/not-found",
        search: "",
        state: { status: props.status, user: props.user },
      });
    }

    if(!countryName || !cityName || countryName !== utils.formatSpotUrl(data.country) || cityName !== utils.formatSpotUrl(data.city)){
      let mcam = "";
      if(cameraPosition){
        mcam = `/${cameraPosition}`;
      }
      history.push({
        pathname: `${utils.getSpotUrl(data.country, data.city, data.url) + mcam}`,
        search: "",
        state: { status: props.status, user: props.user },
      });
    }
    setspotPremium(data);
    const formatDaysForecast = utils.formatDays(data, i18n, t);
    setForcastDays(formatDaysForecast.all);
    setCurrentDayForecast(formatDaysForecast.today);

    if (window.location.pathname && window.location.pathname.includes("camrewind")) {
      setIsCamRewind(true);
    }
    setIsLoading(false);
  };

  const handleRewind = (isRewind) => {
    const {country, city, url} = spotPremium;
    let path = utils.getSpotUrl(country, city, url);
    if (spotPremium.position_camera !== undefined) {
      path += "/" + spotPremium.position_camera;
    }
    if (isRewind) {
      path = path + "/camrewind";
    }
    history.push({
      pathname: path,
      search: "",
      state: { status: props.status, user: props.user },
    });
  };

  const selectCamMultiCam = (cams, camPosition) => {
    if (cams.length === 1) {
      setAllCams(cams);
      return cams[0];
    } else {
      setAllCams(cams);
      if (camPosition === undefined) {
        camPosition = "camera1";
      }
      let exist = false;
      for (let index = 0; index < cams.length; index++) {
        const element = cams[index];
        if (element["position_camera"] === camPosition) {
          exist = true;
          if (props.user.isPremium === true || element["is_premium"] === false || (element["start_premium"] !== undefined && utils.compareDate(element["start_premium"]) >= 0)) {
            setSelectedPosition(index);
            return element;
          } else {
            return element;
          }
        }
      }
      if (exist === false) {
        history.push({
          pathname: "/not-found",
          search: "",
          state: { status: props.status, user: props.user },
        });
      }
    }
  };

  const changeCamera = (position) => {
    const newCamera = allCams[position];
    if (props.user.isPremium === true || newCamera.is_premium === false || (newCamera.start_premium !== undefined && utils.compareDate(newCamera.start_premium) >= 0)) {
      setspotPremium(allCams[position]);
      setSelectedPosition(position);
    }
  };

  return (
    <div className="site-wrapper">
      <section className="surfcam-view">

        {isCamRewind === false ? (
          <>
            {isLoading === true && <LoaderComponent />}
            {isLoading === false && spotPremium && spotPremium.id && (
              <React.Fragment>
                <SpotComponent
                  spot={spotPremium}
                  selectedPosition={selectedPosition}
                  allCams={allCams}
                  changeCamera={changeCamera}
                  handleRewind={handleRewind}
                  forcastDays={forcastDays}
                  currentDayForecast={currentDayForecast}
                />
              </React.Fragment>
            )}
          </>
        ) : (
          <div className="container-xl">
            {isLoading === true && <LoaderComponent />}
            {isLoading === false && spotPremium && spotPremium.id && <CamRewindComponent spot={spotPremium} goback={() => handleRewind(false)} />}
          </div>
        )}
      </section>
    </div>
  );
};

const ForecastTitle = styled.div`
  margin-top: 15px;
  span {
    font-size: 1em;
    padding: 5px 10px;
    padding-left: 10px;
    background-color: black;
    color: #fff;
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  surfcams: state.surfcams,
});

export default connect(mapStateToProps)(React.memo(SpotView));
