import React, {useState, useEffect} from 'react'
import {Trans, useTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import constant from '../utils/constant';
import { AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";
import styled from 'styled-components';


const PremiumCompareTable = (props) => {
    const { isMobile, ...tableProps } = props;
     // isMobile
    // tableAmount

    return (
        <div id="comparePlans">
              {!isMobile() ? (
                <DesktopTable {...tableProps}/>
              ) : (
                <MobileTable {...tableProps }/>
              )}
            </div>
    )
}

const TooltipTextList = ({ text, tooltip, toolIdentifier, mobile }) => {
  const [helpIsOpen, setHelpIsOpen] = useState(false);

  return (
    <>
      <Tooltip placement={mobile ? "left" : "top"} isOpen={helpIsOpen} target={toolIdentifier} toggle={() => setHelpIsOpen(!helpIsOpen)}>
        {tooltip}
      </Tooltip>
      <td className="border-table">
        <span id={toolIdentifier} style={{ display: "flex", width: "fit-content" }}>
          <BiHelpCircle style={{ marginRight: "15px", cursor: "pointer" }} /> <TextList>{text}</TextList>
        </span>
      </td>
    </>
  );
};

const DesktopTable = (props) => {
    const { t } = useTranslation();
    const {tableCurrency, tableAmount, user, selectedPlan, isMonthPlan, saveMoney, monthlyPlan, anualPlan } = props; 
    const [actualPlan, setActualPlan] = useState({});

    useEffect(() => {
      setActualPlan(isMonthPlan ? monthlyPlan : anualPlan);
    }, [])

    return (
        <ComparePlans>
        <h2 className="offset-2">{t("premium_landing_view_plan_compare_plan_table_title")}</h2>
        <div className="container-xl">
          <table>
            <thead>
              <tr>
                <th className="border-table"></th>
                <th>
                  <h3>{t("_PREMIUM")}</h3>
                  <h4>
                    {tableCurrency} {tableAmount}
                  </h4>
                  <h4>
                    {isMonthPlan === false ? (
                      <Trans i18nKey="premium_landing_view_recurring_billing_anual" values={{ currency: tableCurrency, savingMoney: saveMoney }} /> //change 0 to saveMoney value
                    ) : (
                      t("premium_landing_view_recurring_billing_month")
                    )}
                  </h4>
                  <p>{t("premium_landing_view_plan_compare_plan_table_premium_title")}</p>
                </th>
                <th>
                  <h3>{t("_FREE")}</h3>
                  <h4>{tableCurrency} 0.00</h4>
                  <h4>{t("premium_landing_view_plan_compare_plan_table_free_title")}</h4>
                  <p>{t("premium_landing_view_plan_compare_plan_table_free_description")}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-table">
                  <strong>{t("premium_landing_view_plan_compare_cams_title")}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
    
              <tr>
                {/* pelar abTesting logic */}
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_camera_title")}
                  toolIdentifier="tooltip1"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_cams")}
                  mobile={false}
                />
                <td>{t("premium_landing_view_plan_compare_plan_table_camera_free")}</td>
                <td>{t("premium_landing_view_plan_compare_plan_table_camera_premium")}</td>
              </tr>
    
              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_multicam_title")}
                  toolIdentifier="tooltip2"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_premium_cams")}
                  mobile={false}
                />
                <td>
                  <AiFillCheckCircle className="in-plan" />
                </td>
                <td>
                  <AiFillCloseCircle className="not-in-plan" />
                </td>
              </tr>
    
              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_premium_cam_title")}
                  toolIdentifier="tooltip3"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_new_cams")}
                  mobile={false}
                />
                <td>
                  <AiFillCheckCircle className="in-plan" />
                </td>
                <td>
                  <AiFillCloseCircle className="not-in-plan" />
                </td>
              </tr>
    
              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_rewind_title")}
                  toolIdentifier="tooltip4"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_cam_rewind")}
                  mobile={false}
                />
                <td>
                  <AiFillCheckCircle className="in-plan" />
                </td>
                <td>
                  <AiFillCloseCircle className="not-in-plan" />
                </td>
              </tr>
    
              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_multispot_title")}
                  toolIdentifier="tooltip5"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_favorites")}
                  mobile={false}
                />
                <td>{t("premium_landing_favorites_premium")}</td>
                <td>{t("premium_landing_favorites_free")}</td>
              </tr>
    
              <tr>
                <td className="border-table">
                  <strong>{t("premium_landing_view_plan_compare_pronos_title")}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_forecast_expert_title")}
                  toolIdentifier="tooltip6"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_expert_pronos")}
                  mobile={false}
                />
                <td>{t("premium_landing_view_plan_compare_plan_table_forecast_expert_premium")}</td>
                <td>{t("premium_landing_view_plan_compare_plan_table_forecast_expert_free")}</td>
              </tr>

              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_forecast_interpreter_title")}
                  toolIdentifier="tooltip7"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_interpreted_pronos")}
                  mobile={false}
                />
                <td>10 {t("_DAYS")}</td>
                <td>{t("_TODAY")}</td>
              </tr>
    
              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_forecast_data_title")}
                  toolIdentifier="tooltip8"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_standart_pronos")}
                  mobile={false}
                />
                <td>10 {t("_DAYS")}</td>
                <td>3 {t("_DAYS")}</td>
              </tr>
    
              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_wave_quality")}
                  toolIdentifier="tooltip9"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_wave_quality")}
                  mobile={false}
                />
                <td>10 {t("_DAYS")}</td>
                <td>{t("_TODAY")}</td>
              </tr>
    
              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_wave_alert")}
                  toolIdentifier="tooltip10"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_wave_alert")}
                  mobile={false}
                />
                <td>{t("premium_landing_wave_alert_premium")}</td>
                <td>{t("premium_landing_wave_alert_free")}</td>
              </tr>
    
              <tr>
                <td className="border-table">
                  <strong>{t("premium_landing_view_plan_compare_advantage_title")}</strong>
                </td>
                <td></td>
                <td></td>
              </tr>
    
              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_table_ads_title")}
                  toolIdentifier="tooltip11"
                  tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_publicity")}
                  mobile={false}
                />
                <td>
                  <AiFillCheckCircle className="in-plan" />
                </td>
                <td>
                  <AiFillCloseCircle className="not-in-plan" />
                </td>
              </tr>
    
              <tr>
                <TooltipTextList
                  text={t("premium_landing_view_plan_compare_plan_benefit_network_title")}
                  toolIdentifier="tooltip12"
                  tooltip={t("premium_landing_view_plan_compare_plan_benefit_network_tooltip")}
                  mobile={false}
                />
                <td>
                  <AiFillCheckCircle className="in-plan" />
                </td>
                <td>
                  <AiFillCloseCircle className="not-in-plan" />
                </td>
              </tr>
    
              <tr>
                <td className="border-table">{t("premium_landing_view_plan_compare_plan_table_more_advantage")}</td>
                <td></td>
                <td></td>
              </tr>
    
              <tr>
                <td className="border-table"></td>
                <td>
                  {isMonthPlan === false ? (
                    <button disabled={user.nextPlanId === constant.SUBSCRIPTION.ANNUALLY} onClick={() => selectedPlan(actualPlan)}>
                      {user.nextPlanId && user.nextPlanId === constant.SUBSCRIPTION.ANNUALLY
                        ? "Plan Actual"
                        : user.currentPlanId
                        ? user.canUseFreeTrial
                          ? t("premium_landing_view_free_trial")
                          : t("premium_landing_view_get_premium")
                        : t("premium_landing_view_join_now")}
                    </button>
                  ) : (
                    <button disabled={user.nextPlanId === constant.SUBSCRIPTION.MONTHLY} onClick={() => selectedPlan(actualPlan)}>
                      {user.nextPlanId && user.nextPlanId === constant.SUBSCRIPTION.MONTHLY
                        ? "Plan Actual"
                        : user.currentPlanId
                        ? user.canUseFreeTrial
                          ? t("premium_landing_view_free_trial")
                          : t("premium_landing_view_get_premium")
                        : t("premium_landing_view_join_now")}
                    </button>
                  )}
                </td>
                <td>
                  <button disabled={user.nextPlanId === constant.SUBSCRIPTION.FREE} onClick={() => selectedPlan(actualPlan)}>
                    {user.nextPlanId
                      ? user.nextPlanId === constant.SUBSCRIPTION.FREE
                        ? t("premium_landing_view_current_plan")
                        : t("premium_landing_view_update_plan")
                      : t("premium_landing_view_join_now")}
                  </button>
                </td>
              </tr>
              <tr>
                <td className="border-table"> </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ComparePlans>
    )
   
};

const MobileTable = (props) => {
  const {tableCurrency, tableAmount, user, selectedPlan, isMonthPlan, saveMoney, monthlyPlan, anualPlan, isAppleDevice } = props; 
  const { t } = useTranslation();
  const [isPremiumCompare, setIsPremiumCompare] = useState(true);
  const [actualPlan, setActualPlan] = useState({})

  useEffect(() => {
    setActualPlan(isMonthPlan ? monthlyPlan : anualPlan);
  }, [])

  return (
    <ComparePlansMobile>
    <h2 className="offset-2">{t("premium_landing_view_plan_compare_plan_table_title")}</h2>
    <CompareMobileHeader>
      <ul>
        <li>
          <button className={isPremiumCompare === true ? "active" : ""} onClick={() => setIsPremiumCompare(true)}>
            Premium
          </button>
        </li>
        <li>
          <button className={isPremiumCompare === false ? "active" : ""} onClick={() => setIsPremiumCompare(false)}>
            Free
          </button>
        </li>
      </ul>
      <h3>{isPremiumCompare === true ? "Premium" : "Free"}</h3>
      {isPremiumCompare === true ? (
        <React.Fragment>
          <h4>
            {tableCurrency} {tableAmount}
          </h4>
          <h4>
            {isMonthPlan === false ? (
              <Trans i18nKey="premium_landing_view_recurring_billing_anual" values={{ currency: tableCurrency, savingMoney: saveMoney }} /> //change 0 value ro saveMoney value
            ) : (
              t("premium_landing_view_recurring_billing_month")
            )}
          </h4>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h4>{tableCurrency} 0.00</h4>
          <h4>{t("premium_landing_view_plan_compare_plan_table_free_title")}</h4>
        </React.Fragment>
      )}
      <p>{isPremiumCompare === true ? t("premium_landing_view_plan_compare_plan_table_premium_title") : t("premium_landing_view_plan_compare_plan_table_free_description")}</p>
    </CompareMobileHeader>
    <div className="container-xl">
      <table>
        <tbody>
          <tr>
            <td className="border-table">
              <strong>{t("premium_landing_view_plan_compare_cams_title")}</strong>
            </td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_camera_title")}
              toolIdentifier="tooltip1"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_cams")}
              mobile={true}
            />
            <td>
              {isPremiumCompare === true
                ? t("premium_landing_view_plan_compare_plan_table_camera_free")
                : t("premium_landing_view_plan_compare_plan_table_camera_premium")}
            </td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_multicam_title")}
              toolIdentifier="tooltip2"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_premium_cams")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? <AiFillCheckCircle className="in-plan" /> : <AiFillCloseCircle className="not-in-plan" />}</td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_premium_cam_title")}
              toolIdentifier="tooltip3"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_new_cams")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? <AiFillCheckCircle className="in-plan" /> : <AiFillCloseCircle className="not-in-plan" />}</td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_rewind_title")}
              toolIdentifier="tooltip4"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_cam_rewind")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? <AiFillCheckCircle className="in-plan" /> : <AiFillCloseCircle className="not-in-plan" />}</td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_multispot_title")}
              toolIdentifier="tooltip5"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_favorites")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? t("premium_landing_favorites_premium") : t("premium_landing_favorites_free")}</td>
          </tr>

          <tr>
            <td className="border-table">
              <strong>{t("premium_landing_view_plan_compare_pronos_title")}</strong>
            </td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_forecast_expert_title")}
              toolIdentifier="tooltip6"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_expert_pronos")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? t("premium_landing_view_plan_compare_plan_table_forecast_expert_premium") : t("premium_landing_view_plan_compare_plan_table_forecast_expert_free")}</td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_forecast_interpreter_title")}
              toolIdentifier="tooltip7"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_interpreted_pronos")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? 10 + t("_DAYS") : t("_TODAY")}</td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_forecast_data_title")}
              toolIdentifier="tooltip8"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_standart_pronos")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? 10 + t("_DAYS") : 3 + t("_DAYS")}</td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_wave_quality")}
              toolIdentifier="tooltip9"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_wave_quality")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? 10 + t("_DAYS") : t("_TODAY")}</td>
          </tr>
          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_wave_alert")}
              toolIdentifier="tooltip10"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_wave_alert")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? t("premium_landing_wave_alert_premium") : t("premium_landing_wave_alert_free")}</td>
          </tr>

          <tr>
            <td className="border-table">
              <strong>{t("premium_landing_view_plan_compare_advantage_title")}</strong>
            </td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_table_ads_title")}
              toolIdentifier="tooltip11"
              tooltip={t("premium_landing_view_plan_compare_plan_table_tooltip_publicity")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? <AiFillCheckCircle className="in-plan" /> : <AiFillCloseCircle className="not-in-plan" />}</td>
          </tr>

          <tr>
            <TooltipTextList
              text={t("premium_landing_view_plan_compare_plan_benefit_network_title")}
              toolIdentifier="tooltip12"
              tooltip={t("premium_landing_view_plan_compare_plan_benefit_network_tooltip")}
              mobile={true}
            />
            <td>{isPremiumCompare === true ? <AiFillCheckCircle className="in-plan" /> : <AiFillCloseCircle className="not-in-plan" />}</td>
          </tr>

          <tr>
            <td className="border-table">{t("premium_landing_view_plan_compare_plan_table_more_advantage")}</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td className="border-table"></td>
            <td>
              {!isAppleDevice && ( isPremiumCompare === true ? (
                isMonthPlan === false ? (
                  <button disabled={user.nextPlanId === constant.SUBSCRIPTION.ANNUALLY} onClick={() => selectedPlan(actualPlan)}>
                    {user.nextPlanId && user.nextPlanId === constant.SUBSCRIPTION.ANNUALLY
                      ? t("premium_landing_view_current_plan")
                      : user.currentPlanId
                      ? user.canUseFreeTrial
                        ? t("premium_landing_view_free_trial")
                        : t("premium_landing_view_get_premium")
                      : t("premium_landing_view_join_now")}
                  </button>
                ) : (
                  <button disabled={user.nextPlanId === constant.SUBSCRIPTION.MONTHLY} onClick={() => selectedPlan(actualPlan)}>
                    {user.nextPlanId && user.nextPlanId === constant.SUBSCRIPTION.MONTHLY
                      ? t("premium_landing_view_current_plan")
                      : user.currentPlanId
                      ? user.canUseFreeTrial
                        ? t("premium_landing_view_free_trial")
                        : t("premium_landing_view_get_premium")
                      : t("premium_landing_view_join_now")}
                  </button>
                )
              ) : (
                <button disabled={user.nextPlanId === constant.SUBSCRIPTION.FREE} onClick={() => selectedPlan(actualPlan)}>
                  {user.nextPlanId
                    ? user.nextPlanId === constant.SUBSCRIPTION.FREE
                      ? t("premium_landing_view_current_plan")
                      : t("premium_landing_view_update_plan")
                    : t("premium_landing_view_join_now")}
                </button>
              ))}
            </td>
          </tr>
          <tr>
            <td className="border-table"> </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ComparePlansMobile>
  )
};

const TextList = styled.p`
  margin: 0;
  padding: 0 !important;
  font-size: 1em !important;
  font-weight: 400 !important;
  width: fit-content;
  cursor: pointer;
`;

const CompareMobileHeader = styled.div``;

const ComparePlansMobile = styled.div`
  display: grid;

  ul {
    display: inline-flex;

    li {
      list-style: none;
    }
  }

  button {
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    font-weight: 800;
    width: 100%;
    font-size: 0.7em;
    width: 100px;
    margin-right: 15px;
    border: 1px solid #4d4a4a;
  }

  .active {
    background-color: #2b5283;
    color: #fff;
  }

  @media (min-width: 1000px) {
    display: none !important;
  }

  h2 {
    font-weight: 700;
    padding-left: 10px;
    font-weight: 700;
    color: #000;
    margin-bottom: 20px;
    margin-left: 20px;
    font-size: 1.2em;
    text-align: left;
    margin-top: 10px;
  }

  h3 {
    font-size: 1em;
    font-weight: 700;
    margin-left: 35px;
    color: #000;
    margin-top: 10px;
  }

  h4 {
    font-size: 0.9em;
    font-weight: 700;
    color: #474747;
    margin-left: 35px;
  }

  p {
    font-size: 0.8em;
    padding: 0px 35px;
    font-weight: 500;
  }

  /* button{
      border: none;
      padding: 12px 20px;
      border-radius: 5px;
      background-color: #000;
      color: #fff;
      font-weight: 800;
      width: 100%;
      font-size: 0.7em;
      padding: 0p;
      width: 60%;
    } */

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  table {
    margin-bottom: 100px;

    button {
      border: none;
      padding: 8px 15px;
      border-radius: 5px;
      background-color: #fff;
      color: #000;
      font-weight: 800;
      width: 100%;
      font-size: 0.8em;
      width: 150px;
      margin-left: 15px;
      border: 1px solid #4d4a4a;
    }
  }

  th,
  td {
    text-align: center;
  }

  td {
    padding: 15px;
    font-size: 0.8em;

    .in-plan {
      color: #427d0f;
    }

    .not-in-plan {
      color: #ed2323;
    }
  }

  td:nth-child(2) {
    padding: 0px 0px;
  }

  .border-table {
    border-right: 1px solid #e8e6e6;
    text-align: left !important;
    font-size: 0.9em;
    padding-right: 50px;
  }
`;

const ComparePlans = styled.div`
  display: grid;

  @media (max-width: 1000px) {
    display: none !important;
  }

  h2 {
    font-weight: 700;
    padding-left: 10px;
    font-weight: 700;
    color: #000;
    margin-bottom: 40px;
    margin-top: 20px;

    @media (max-width: 1000px) {
      margin-left: 30px;
      font-size: 1.4em;
      text-align: left;
      padding-left: 0px;
      margin-top: 10px;
    }
  }

  h3 {
    font-size: 1.4em;
    font-weight: 700;
    color: #000;
  }

  h4 {
    font-size: 0.9em;
    font-weight: 700;
    color: #474747;
  }

  p {
    font-size: 0.8em;
    padding: 0px 30px;
    font-weight: 500;
  }

  h6 {
    font-size: 0.75em;
    color: #000;
    font-weight: 600;
  }

  button {
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    background-color: #2b5283;
    color: #fff;
    font-weight: 800;
    width: 100%;
    font-size: 0.7em;
    padding: 0p;
    width: 80%;
  }

  button:disabled,
  button[disabled] {
    background-color: #d9dfe6 !important;
    box-shadow: none;
    color: #8da2c0 !important;
  }

  svg {
    width: 22px;
    height: 22px;
  }

  table {
    margin-bottom: 100px;
    width: 1000px !important;
  }

  th,
  td {
    text-align: center;
  }

  td {
    padding: 15px;
    font-size: 0.9em;

    .in-plan {
      color: #427d0f;
    }

    .not-in-plan {
      color: #ed2323;
    }
  }

  .border-table {
    border-right: 1px solid #e8e6e6;
    text-align: left !important;
    font-size: 0.9em;
    padding-right: 50px;
  }
`; 

const mapStateToProps = (state) => ({
  user: state.user,
  status: state.status,
  isAppleDevice: state.isAppleDevice
})

export default connect(mapStateToProps)(PremiumCompareTable);