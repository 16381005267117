import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChartComponentSwell from "./chartComponentSwell";
import ChartComponentWind from "./chartComponentWind";
import ChartComponentRating from "./chartComponentRating";
import { useTranslation } from "react-i18next";

const ChartComponent = (props) => {
  const {chartData, user, windData, spotName} = props;
  const [chart, setChart] = useState(chartData);
  const [wind, setWind] = useState(windData);
  const { t } = useTranslation();


  useEffect(() => {
    console.log("chartData:", chartData[0], "windData:", windData[0]);
    setChart(chartData);
    setWind(windData);
  },[]);

  return (
    <>
      <Divider /> 
      <Container>
        <ChartComponentWind windData={wind} premium={user.isPremium} spotName={spotName} />
        <ChartComponentSwell chartData={chart} premium={user.isPremium} spotName={spotName} />
        <ChartComponentRating chartData={chart} premium={user.isPremium} spotName={spotName}/>
      </Container>
    </>
  );
};
const Divider = styled.hr`
  background: #e4e7ed;
  margin: 2em 0 1em 0;
`

const Container = styled.div`
  >div{margin-bottom: 45px;}
  @media(max-width: 1000px){
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    >div{margin-bottom: 15px;}   
  }
`

export default React.memo(ChartComponent);
