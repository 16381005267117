import React, { useEffect, useState } from "react";
import { browserName, browserVersion, osName } from "react-device-detect";
import { Trans, useTranslation } from "react-i18next";
import ReactJWPlayer from "react-jw-player";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import useGeo from "../hooks/geoHook";
import useInterval from "../hooks/useIntervalHook";
import geoLocationService from "../modules/geoLocationService";
import { createStreamingPlayList, needCheckStreaming, showActionAB } from "../modules/handleABService";
import liveStreamingMetric from "../modules/liveStreamingMetric";
import plans from "../modules/plans";
import streamingReducer from "../reducers/streamingReducer";
import constant from "../utils/constant";
import utils from "../utils/utils";
import WarningContainer from "./warningContainer";
import socket from "../modules/socket";
import { getSignedUrl } from "@aws-sdk/cloudfront-signer"; 


 //signedURL
 const privateKey = '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCkeCMqPQJA1VIs\neP5XTburPfCRDPldAGWyB7OwrZ64TJqmVBpGeFfbC4C7WjkZaXIEdR8Bpcp7WquG\n0VxqSzvtXMUW3LKFAazxvkBFZIkYzihiTwIQyrA613PkX/SWTq70gRemhEXKjZS1\n36TbnEar1iZRAGYcTDUZ7xTZ1tbctKtTeUvpCrM5WUmMBZAIPXOxDDlMd7EVoQ1i\nbp2gEySlze1qsudBeGxLdlOftjzCYn0oQR+aHsS12rK/31qLvTNnTJLgA0WyhKEh\ntZphSVfxtpSc1/qYX/f/4ys6ZFp7bTq4QEcevK1XFYkD1OnkLaklBLkHPGWQhfJj\n5KzSEG/DAgMBAAECggEAAtCBfYwMuVJNNTCe3ZyZbQ1CzCCmNca3dsv18byO3kWF\nUfpXdsRBcKCqQ8p/9qTczCG69ZrCM8kx7T0w8Sq3bFDtmH4PWgJU1NA2JlnZlMCW\nZVRHy0TErkUYmRxhQZ84FDwPTQyvaw9wL4qTtiRe+zrPbgxtMufE8twqBa5M//ra\neywC0bvYPbZswcA/2lUzOgMBUqp42eXpz9ajR1qMraDawTG4UaQaAy2NT2xfjJ6F\nKBAyNJ8Jvt1s96Y9RIXsYUdVxTsdhZvmC3mvhrM+oeX6v6eO+zj9an0ca5G28+QD\n49f8E6ciOUXHLUi9iFZ/FcicjY/+dmubprwm6Lz4CQKBgQDHmMuL+OMuI9ST3g5t\nlvSv+Dg53DJRumauVHSB+5Lz0QUOdZX39sQlfrGMpP5BdlnFJurCEHiCW8Se5afp\no4/Xzn9kuCLGHso8jKG1PjxhSbHcFQd1imxBPoUQEjsYUQhlcYQtYfcP8gSjeYH7\nxpSsr89Bx+ssjBmmBGAwZI0XuQKBgQDS8iYpdILU9sdeyMkATEsInCC7msZz7g5/\nEYbOmtayN53BYszsss+/X98Hz/25GgcsDy5bhmXrLTFkwWcNqmNAKKyG76Kx28Oa\nQOjAo/V9h1nUk50+OlszZK+A7Bi0Rj/ndKUQWNuOdvwFmPOryVxmMfCRHaMqFd6H\namexEW+JWwKBgDHdbXTRwEgFsZUjk+0zGnxgAgQKjBO5AvAor1bN+B+rp5y+KbJi\nVwybA1aE3fSS0ciceWf0X3cJ4CxmabXIJCahM1iBXEas6I+J/thd7Q3NMH8wTcXC\nyFrgeLPUKilgDT3yxzfjUqsMJraEo8G4Eeq2nYvsm0ds1PeR/kn4xPsZAoGAZ3Mx\nm+rzrpVHuQgm6up/cb1/oAGLiZcZrN9fiTsOR2BXEVvuu7YFhAj0S9SIzZoiIk1f\noqsKmIcVTiA69cy11+UalyI2W5s3FhL3EmJWJjWWrFWoK7hauZ2LJGKkyuQYut3N\nhXLOWdOoTOZxubGKiuO64q/yMNWAyFA6ge/XDzkCgYAP9jECmYu+e61S+742gByX\nDyW66OgrvBTSUfXnTekaAOi/OaafpsCwQSykzxXlhrkYA6x7xCk38gAxISBpu6rp\nU3fhpAJjePWwVGEw68/RUn1TNOrbvXMj69k8BLzI3963BK4k2I5KilTVsCr48gtX\nmkr3NS+Z0PxBTmTszE6d9A==\n-----END PRIVATE KEY-----';
 const keyPairId = "K2MZLWT7XNJI9R";
 const dateLessThan = "2024-02-02"; // any Date constructor compatible
 


const StreamComponent = (props) => {
  const {
    user,
    cameraId: cameraIdProp,
    endPremium,
    status,
    socket_action,
    isMultiSpot,
    endStreaming,
    needOverlay,
    setIsPlayingCams,
    isWizzard,
    startPremium,
    guestRestriction,
    isFree,
    surfCamImg,
    isPremium,
    warning,
    autoPlay,
    src,
    lastRewinds,
  } = props;
  const { t, i18n } = useTranslation();

  const [cameraId] = useState(cameraIdProp);
  const [timeWatched, setTimeWatched] = useState(0);
  const [geoLocationInfo, setGeoLocationInfo] = useGeo("country", null);
  const [maxFreeTime, setMaxFreeTime] = useState(false);
  const [isWatching, setIsWatching] = useState(true);
  const [isAfk, setIsAfk] = useState(false);
  const [videoPlayerId, setVideoPlayerId] = useState(uuidv4());
  const [isDateAvailable, setIsDateAvailable] = useState(utils.isDateAvailable(endPremium));
  const [isWarning, setIsWarning] = useState(false);
  const [userTime, setUserTime] = useState(30);
  const [isPaidTime, setIsPaidTime] = useState(false);
  const [fewMinutes, setFewMinutes] = useState(false);
  // Get Annual Plan price to use in texts
  const [amountPlan, setAmountPlan] = useState(0);
  const [currency, setCurrency] = useState('$');
  const [signedUrl,setSignedUrl] = useState(src);
  const isLogged = status === "logged";

  const getPlanAmount = async () => {
    let data = await plans.getPlan(3, user.country);
    setAmountPlan(data.amount);
    setCurrency(data.currency_code);
  };

  useEffect(() => {
    if (isLogged) {
      getPlanAmount();
    }
  }, [isLogged]);

  useEffect(() => {
    console.log(signedUrl);
    setSignedUrl(getSignedUrl({
      url:src,
      keyPairId,
      dateLessThan,
      privateKey,
    }));
    
    if (geoLocationInfo === null) {
      geoLocationService.getGeoLocationInfo().then((i) => {
        setGeoLocationInfo({
          country: i && i.country_code ? i.country_code : "UNKNOWN",
        });
      });
    }
    setUserStreamingTime();
  }, []);
  useEffect(() => {
    console.log('URL firmada ' + signedUrl);
  },[signedUrl]); 
  useEffect(() => {
    if (socket_action?.action === "STOP_STREAMING") {
      pauseStream();
    }
  }, [socket_action]);

  const setUserStreamingTime = () => {
    if (user && user.currentPlanId === constant.SUBSCRIPTION.FREE) {
      if (user.availableStreamingTime > 0) {
        setUserTime(user.availableStreamingTime);
      } else {
        if (user.paidStreamingTime > 0) {
          setUserTime(user.paidStreamingTime);
          setIsPaidTime(true);
        } else {
          if (isMultiSpot === true) {
            endStreaming();
          }
          setUserTime(0);
        }
      }
    }
  };

  const handleUserStreamingTime = () => {
    let currentUserTime = userTime;
    if (currentUserTime > 0) {
      currentUserTime = currentUserTime - 5;
      if (isPaidTime) {
        streamingReducer.saveTemporal(0, currentUserTime);
      } else {
        streamingReducer.saveTemporal(currentUserTime, user.paidStreamingTime);
      }
      if (currentUserTime === 0) {
        if (isMultiSpot) {
          endStreaming();
        }
        setUserStreamingTime();
      } else {
        setUserTime(currentUserTime);
      }
    }
  };

  const sendStreamEvent = async (currentEventType, currentTimeWatched) => {
    var userLiveStreaming = {
      user_id: user.id,
      user_plan_id: user.currentPlanId,
      session_id: user.id,
      event_type: currentEventType,
      camera_id: cameraId,
      time_watched: currentTimeWatched,
      user_device: `${osName} - ${browserName} ${browserVersion}`,
      user_country_location: geoLocationInfo !== null ? geoLocationInfo.country : "",
    };
    liveStreamingMetric.postMetric(userLiveStreaming);
  };

  const onStreamStart = () => {
    socket.onPlayStreaming();
    sendStreamEvent(constant.STREAMING_EVENT_TYPE.start, 0);
  };

  const onStreamPlay = () => {
    setIsAfk(false);
    socket.onPlayStreaming();
    sendStreamEvent(constant.STREAMING_EVENT_TYPE.play, 0);
  };

  const exitFullscreen = () => {
    if (document !== undefined && document.fullscreenElement !== null) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else {
        document.requestFullscreen();
      }
    }
  };

  const isMobile = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return true;
    } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return true;
    }
    return false;
  };

  useInterval(() => {
    if (!isLogged && (isPremium || (timeWatched >= constant.STREAMING_EVENT_TYPE.maxFreeTime && !maxFreeTime))){      
      setMaxFreeTime(true);
    }

    if (isLogged && isAfk && !isMobile()) {
      if (needOverlay && !needOverlay) {
        setIsPlayingCams(false);
      } else {
        setIsWatching(false);
        setIsAfk(false);
        exitFullscreen();
      }
      window.jwplayer(videoPlayerId).stop();
    }

    try {
      const playerStatus = window.jwplayer(videoPlayerId).getState();
      if (!isAfk && isWatching && playerStatus === "playing") {
        if (needCheckStreaming(user, timeWatched, isWizzard)) {
          handleUserStreamingTime();
        }
        if (isWizzard !== true) {
          setTimeWatched(timeWatched + 5);
          sendStreamEvent(constant.STREAMING_EVENT_TYPE.progress, 5);
          if (userTime < 120 && user.isPremium !== true && isMultiSpot !== true) {
            setFewMinutes(true);
            exitFullscreen();
          }
        }
      }
    } catch (error) {}
  }, 5000);

  const onStreamPause = (e) => {
    if (e && e.pauseReason !== "interaction") {
      window.jwplayer(videoPlayerId).play();
    }
    sendStreamEvent(constant.STREAMING_EVENT_TYPE.pause, 0);
  };

  const pauseStream = () => {
    setIsAfk(true);
    setIsWatching(false);
    window.jwplayer(videoPlayerId).stop();
  };

  return (
    <>
      {isLogged && !user.isPremium && fewMinutes && isMultiSpot !== true && !isWizzard && <WarningContainer type="alert" />}
      {isLogged && startPremium !== undefined && isMultiSpot !== true && !isWizzard && <WarningContainer type="info" bePremium={true} daysLeft={utils.compareDate(startPremium)} />}
      {!isMultiSpot && showActionAB(user) && <WarningContainer type="info" isAB={true} />}

      {/* USER WITH NO SESSION, ASK THE USER TO REGISTER AFTER WATCHING THE CAM SOME SECONDS */}
      {guestRestriction !== false && ((maxFreeTime && !isLogged) || (!isFree && !isLogged)) && (
        <StreamHandleUser style={{ backgroundImage: `url(${surfCamImg})` }}>
          <OverlayStreamImg />
          <StreamHandleContent>
            <IconPadLock src={require("../assets/icons/padlock.svg")} />
            <StreamTitle>
              <Trans i18nKey="cam_player_not_logged_wall_main_label" components={{ bold: <strong /> }} />
            </StreamTitle>
            <StreamTextLock>
              <Trans
                i18nKey="cam_player_not_logged_wall_label" // optional -> fallbacks to defaults if not provided
                components={{ italic: <i />, bold: <strong /> }}
              />
            </StreamTextLock>
            <StreamOverlayButtonBox>
              <Link to="/premium" id="streaming-not-logged-register">
                {t("cam_player_not_logged_register_button")}
              </Link>
              <Link
                to={{
                  pathname: "/access",
                  state: {
                    prevPath: window.location.pathname,
                    isRegister: false,
                  },
                }}
                id="streaming-not-logged-login">
                {t("cam_player_not_logged_login_button")}
              </Link>
            </StreamOverlayButtonBox>
          </StreamHandleContent>
        </StreamHandleUser>
      )}

      {user.isPremium !== true && isLogged && isPremium ? (
        <StreamHandleUser style={{ backgroundImage: `url(${surfCamImg})` }}>
          <OverlayStreamImg />
          <StreamHandleContent>
            <IconPadLock src={require("../assets/icons/padlock.svg")} />
            <StreamTitle style={{ fontSize: "1.5em", marginBottom: "15px" }}>
              <Trans i18nKey="access_wall_title_camera_premium" components={{ bold: <strong /> }} />
            </StreamTitle>
            <StreamTextLock style={{ fontSize: "1.2em" }}>
              <Trans
                i18nKey="access_wall_description_camera_premium" // optional -> fallbacks to defaults if not provided
                values={{
                  endPremium: utils.formateDateMonthDay(endPremium, i18n.language, t("_SEPARATOR")),
                }}
                components={{ italic: <i />, bold: <strong /> }}
              />
            </StreamTextLock>
            <StreamOverlayButtonBox>
              <Link
                to={{
                  pathname: "/premium",
                  state: {
                    prevPath: window.location.pathname,
                    isRegister: false,
                  },
                }}
                className="premium-button"
                id="streaming-premium-cam-button">
                {user.canUseFreeTrial && !user.isPremium ? t("access_wall_button_free_camera_premium") : t("access_wall_button_camera_premium")}
              </Link>
            </StreamOverlayButtonBox>
          </StreamHandleContent>
        </StreamHandleUser>
      ) : (isFree || isLogged) && (!maxFreeTime || isLogged || !guestRestriction) && userTime > 0 ? (
        <>
          {isPremium !== true || user.isPremium || isDateAvailable ? (
            <>
              <StreamContainer>
                {(warning || isWarning) && isMultiSpot !== true && !isWizzard && <WarningContainer type="warning" />}

                <div style={{ position: "relative" }}>
                  {geoLocationInfo !== null && signedUrl !== undefined && (
                    <ReactJWPlayer
                      // aspectRatio="16:9"
                      playerId={videoPlayerId}
                      isAutoPlay={autoPlay}
                      isMuted={user.isPremium ? true : isWizzard ? true : false}
                      controls={false}
                      controlbar={false}
                      playerScript="https://content.jwplatform.com/libraries/Yo4AWAlL.js"
                      playlist={createStreamingPlayList(surfCamImg, signedUrl, lastRewinds, isWizzard, user)}
                      onAutoStart={() => onStreamStart()}
                      onPause={(e) => onStreamPause(e)}
                      onResume={() => onStreamPlay()}
                      onError={() => setIsWarning(true)}
                      customProps={{
                        floating: {
                          mode: "never",
                        },
                      }}
                    />
                  )}
                  {isMultiSpot !== true && !isWatching && (
                    <StreamAlert id="streamAlert" style={{ top: user.isPremium ? "0px" : "40px" }}>
                      <div>{!!socket_action && socket_action?.action === "STOP_STREAMING" && <h1>{t("cam_player_multiple_devices_streaming_text")}</h1>}</div>
                    </StreamAlert>
                  )}
                </div>
              </StreamContainer>
            </>
          ) : (
            <StreamHandleUser style={{ backgroundImage: `url(${surfCamImg})` }}>
              <OverlayStreamImg />
              <StreamHandleContent>
                <IconPadLock src={require("../assets/icons/padlock.svg")} />
                <StreamTitle style={{ fontSize: "1.5em", marginBottom: "15px" }}>
                  <Trans i18nKey="cam_player_is_premium_cam_title" components={{ bold: <strong /> }} />
                </StreamTitle>
                <StreamTextLock style={{ fontSize: "1.2em" }}>
                  <Trans
                    i18nKey="cam_player_is_premium_cam_description" // optional -> fallbacks to defaults if not provided
                    values={{
                      endPremium: utils.formateDateMonthDay(endPremium, i18n.language, t("_SEPARATOR")),
                    }}
                    components={{ italic: <i />, bold: <strong /> }}
                  />
                </StreamTextLock>
                <StreamOverlayButtonBox>
                  <Link
                    to={{
                      pathname: "/premium",
                      state: {
                        prevPath: window.location.pathname,
                        isRegister: false,
                      },
                    }}
                    className="premium-button"
                    id="streaming-premium-cam-button">
                    {user.canUseFreeTrial && !user.isPremium ? t("subscription_premium_cam_free_trial") : t("subscription_premium_cam")}
                  </Link>
                </StreamOverlayButtonBox>
              </StreamHandleContent>
            </StreamHandleUser>
          )}
        </>
      ) : (
        isLogged && (
          <StreamTimeOver style={{ backgroundImage: `url(${surfCamImg})` }}>
            <OverlayStreamImg />
            <StreamHandleMinutes>
              <StreamTextIcon>{t("cam_player_limit_minutes_title")}</StreamTextIcon>
              <StreamTitle style={{ fontSize: "1.5em", marginBottom: "15px" }}>{t("cam_player_limit_minutes_description")}</StreamTitle>
              <StreamTextLock style={{ fontSize: "1.2em" }}>
                <Trans i18nKey="cam_player_limit_minutes_user_limit" values={{ annualPriceMonthly: (amountPlan / 12).toString().substring(0, 4), currencyCode: currency }} components={{ italic: <i />, bold: <strong /> }} />
              </StreamTextLock>
              <StreamOverlayButtonBox
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px",
                }}>
                <Link
                  to={{
                    pathname: "/premium",
                    state: {
                      prevPath: window.location.pathname,
                      isRegister: false,
                    },
                  }}
                  className="premium-button"
                  id="streaming-premium-cam-button">
                  {t("cam_player_limit_minutes_button")}
                </Link>
              </StreamOverlayButtonBox>
              <StreamOverlayButtonBox
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px",
                }}></StreamOverlayButtonBox>
            </StreamHandleMinutes>
          </StreamTimeOver>
        )
      )}
    </>
  );
};

const StreamHandleMinutes = styled.div`
  position: absolute;
  top: -2%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  @media (min-width: 1000px) {
    top: 15% !important;
  }

  h3:first-child {
    margin-bottom: 40px;

    @media (max-width: 1000px) {
      margin-top: 20px;
      font-size: 1.2em !important;
      margin-bottom: -6px;
    }
  }

  h3:nth-child(2) {
    @media (max-width: 1000px) {
      margin-top: 15px !important;
      margin-bottom: 7px !important;
    }

    @media (max-width: 370px) {
      padding: 0px !important;
      font-size: 0.85em !important;
    }
  }

  p {
    margin-bottom: 20px;
    @media (max-width: 1000px) {
      padding-bottom: 13px !important;
      margin-bottom: 0px;
    }

    @media (max-width: 370px) {
      padding: 2px !important;
      font-size: 0.8em !important;
      margin-bottom: 15px;
    }
  }

  a {
    width: 400px !important;
    margin-bottom: -5px !important;
  }
`;

const StreamTextIcon = styled.h3`
  text-align: center;
  top: 51%;
  color: #ffffffeb;
  font-size: 2em;
  font-weight: bold;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px !important;
  left: 0;
  right: 0;

  @media (max-width: 1000px) {
    font-size: 1.8em !important;
    width: 100% !important;
    margin-bottom: -10px !important;
    top: 32%;
  }
`;

const StreamTimeOver = styled.div`
  min-height: 216px;
  font-family: "Poppins", sans-serif;
  position: relative;
  margin-bottom: 30px;

  @media (min-width: 1000px) {
    min-height: 360px;
    text-align: center;
    border: solid 1px #ebebeb;
    border-radius: 0.25rem !important;
    background-size: cover;
  }

  svg {
    color: #fff;
    width: 70px;
    height: 70px;
  }
`;

const StreamHandleContent = styled.div`
  position: absolute;
  top: -2%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  @media (min-width: 1000px) {
    top: 15% !important;
  }

  h3 {
    margin-top: 30px !important;
  }
`;

const StreamHandleUser = styled.div`
  min-height: 216px;
  font-family: "Poppins", sans-serif;
  position: relative;
  margin-bottom: 30px;

  @media (min-width: 1000px) {
    min-height: 360px;
    text-align: center;
    border: solid 1px #ebebeb;
    border-radius: 0.25rem !important;
    background-size: cover;
  }
`;

const StreamContainer = styled.div`
  .jwplayer {
    border-radius: 10px !important;
    margin-bottom: 9px !important;
  }

  .jw-wrapper {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .jw-logo {
    margin-top: 20px !important;
    margin-left: 15px !important;
    width: 150px !important;
    height: 50px !important;

    @media (max-width: 1000px) {
      margin-top: 20px !important;
      margin-left: 10px !important;
      width: 150px !important;
      height: 23px !important;
    }
  }

  .jw-related-btn {
    display: none !important;
  }

  .jw-icon-rewind {
    display: none !important;
  }

  .jw-icon-next {
    display: none !important;
  }

  .jw-nextup-container {
    display: none !important;
  }

  .jw-preview {
    background-size: cover !important;
  }

  .jw-icon-volume {
    display: none !important;
  }

  .jw-error-msg {
    display: none !important;
  }

  .jw-slider-container {
    display: none !important;
  }
`;

const StreamAlert = styled.div`
  width: 100%;
  position: absolute;
  z-index: 99999;
  background-color: #fffc;
  text-align: center;
  bottom: 0;
  border-radius: 5px;
  width: 100%;
  top: 0px !important;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  div {
    position: relative;
    top: 35% !important;
    margin-left: -10px;

    h1 {
      font-size: 1.7em;
      margin-bottom: 20px;
    }

    a {
      padding: 10px;
      background-color: #425781;
      color: #fff !important;
      border-radius: 5px;
    }
  }
`;

const OverlayStreamImg = styled.div`
  background-color: #00000050;
  width: 100%;
  height: 411px;
  position: relative;
  border-radius: 5px;

  @media (max-width: 1000px) {
    width: 100%;
    height: 215px;
  }
`;

const StreamTitle = styled.h3`
  text-align: center;
  top: 51%;
  color: #ffffffeb;
  font-size: 1.7em;
  font-weight: bold;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  @media (max-width: 1000px) {
    font-size: 0.85em !important;
    width: 100% !important;
    top: 32%;
  }
`;

const StreamTextLock = styled.p`
  vertical-align: middle;
  align-items: center;
  text-align: center;
  margin: 0;
  -ms-transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 64%;
  font-size: 1.3em;
  top: 60%;
  color: #ffffffeb !important;

  @media (max-width: 1000px) {
    padding: 20px !important;
    font-size: 0.8em !important;
    padding-top: 0px !important;
    width: 100% !important;
    top: 41% !important;
  }
`;

const IconPadLock = styled.img`
  width: 8%;
  top: 34%;
  filter: invert(1);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  @media (max-width: 1000px) {
    display: none !important;
  }
`;

const StreamOverlayButtonBox = styled.div`
  vertical-align: middle;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 0;
  top: 80%;
  margin-top: 30px;
  -ms-transform: translateY(-50%);
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right: 0;

  @media (max-width: 1000px) {
    display: grid;
    margin-top: -15px !important;

    a {
      padding: 10px;
      font-size: 0.9em;
    }

    a:nth-child(1) {
      margin-bottom: 5px !important;
    }

    a:nth-child(2) {
      margin-top: 5px !important;
    }
  }

  @media (max-width: 450px) {
    a {
      padding: 10px 0px !important;
    }
  }

  a:nth-child(1) {
    padding: 10px 25px;
    background-color: #ffffffeb;
    border-radius: 5px;
    color: #000;
    margin: 10px;
  }

  a:nth-child(2) {
    padding: 10px 25px;
    background-color: #425781;
    border-radius: 5px;
    color: #ffffffeb;
    margin: 10px;
  }

  .premium-button {
    color: #fff !important;
    background-color: #5db24a !important;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  socket_action: state.socket,
});

export default connect(mapStateToProps)(StreamComponent);
