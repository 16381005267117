import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";


const CountDown = (props) => {
  const {time} = props;
  const [countdown, setCountDown] = useState(time);

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);

  const timerId = useRef();

  useEffect(() => {

    timerId.current = setInterval(() => {
      setCountDown(prev => prev - 1);
    }, 1000);
    return () => clearInterval(timerId.current);

  }, []);

  useEffect(() => {
    if(countdown <= 0) return clearInterval(timerId.current);
    formatTime(countdown);
  }, [countdown]);

  //Esta funcion debe actualizar los siguientes estados: seconds, minutes, hours, days
  const formatTime = (time) => {
    
    const days = Math.floor(time / (24 * 60 * 60));
    const hours = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((time % (60 * 60)) / 60);
    const seconds = time % 60;

    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
  }

  const roundTime = (time) => ((time/10) < 1 ? '0' + time : time);  


  return (
    <CountDownBox>
      <TimeBox>{roundTime(days)} <TimeDescription>dias</TimeDescription></TimeBox>
      <TimeBox>{roundTime(hours)} <TimeDescription>horas</TimeDescription></TimeBox>
      <TimeBox>{roundTime(minutes)} <TimeDescription>minutos</TimeDescription></TimeBox>
      <TimeBox>{roundTime(seconds)} <TimeDescription>segudos</TimeDescription></TimeBox>
    </CountDownBox>
  )
}

const CountDownBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 450px;
  margin: 0 auto;

  @media(max-width: 450px){
    width: 100%;
  }
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 2ch;
  height: 2ch;
  padding: 40px;
  padding-bottom: 50px; 
  color: #004986;
  background: #fff;
  font-size: 24px;
  font-weight: 900;
  border-radius: 8px;
  box-shadow: 0px 8px 15px rgba(0,0,0, 0.3);
  overflow: hidden;

  @media(max-width: 1000px){
    font-size: 16px;
    padding: 30px;
    padding-bottom: 40px; 
  }
`

const TimeDescription = styled.span`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #004986;
  color: #fff;
  padding: 3.5px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  @media(max-width: 1000px){
    font-size: 10px;
  }
`

export default CountDown;