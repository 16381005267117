import store from "../utils/store";

class SpotReducer {

  saveSpot(spot) {
    store.dispatch({
      type: "SPOT",
      data: spot,
    });
  }
}

const spotReducer = new SpotReducer();

export default spotReducer;