import instance from "./service";
import sponsorsReducer from "../reducers/sponsorsReducer";
import store from "../utils/store"

class Sponsors {
  async getSponsors() {
    const storeData = store.getState();
    const result = await instance.get("/getSponsors", {params: {
      country:  storeData.country
    }});
    const sponsors = this.formatSponsors(result);
    sponsorsReducer.saveSponsors(sponsors);
  }

  formatSponsors(data) {
    let sponsors = {};
    for (let index = 0; index < data.length; index++) {
      const sp = data[index];
      if (sponsors[sp.screen_position] === undefined) {
        sponsors[sp.screen_position] = [];
      }
      sponsors[sp.screen_position].push(sp);
    }
    return sponsors;
  }
}

const sponsors = new Sponsors();

export default sponsors;
