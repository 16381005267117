import React, { Component, lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import NavbarComponent from "../../components/navbarComponent";
import { connect } from "react-redux";


const EmailView = lazy(() => import("../../components/emailWall"));


function PublicRoute(props) {
  let { children, component, user, loggedIn, isAuthenticated, ...rest } = props
  let needEmailValidate = rest.emailValidate !== undefined ? rest.emailValidate : true;
  if(needEmailValidate === true && props.user.status === 'PENDING_ACTIVATION'){
    component = EmailView
  }
  const Component = component;
  let routeProps = loggedIn === 'logged' ? rest : props
  return (
    <>
      <NavbarComponent />
      <Route 
      { 
        ...rest
      }
      
      render={({location}) => {
          return <Component {...props} />
      }}
       />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.status,
    user: state.user,
  };
};

export default connect(mapStateToProps)(PublicRoute);