import io from 'socket.io-client';
import auth from "./auth";
import constant from "../utils/constant";
import store from "../utils/store";
import utils from "../utils/utils";
import socketReducer from "../reducers/socketReducer";

class Socket {
  constructor() {
    this.socketInstance = null
    this.fingerprint = ''
  }
  
  async instantiate(fingerprint) {
    const currentState = store.getState();
    this.fingerprint = fingerprint

    this.socketInstance = io(process.env.REACT_APP_CORE_URL, {
      auth: {
        token: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
        fingerPrint: this.fingerprint
      }
    });

    this.socketInstance.on('connect', () => {
      this.socketInstance.emit('register_device', {
        token: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
        fingerPrint: this.fingerprint,
        id: this.socketInstance.id
      })
    });

    this.socketInstance.on('deviceConnected', () => {
      //console.log('Dispositivo conectado correctamente');
    });

    this.socketInstance.on('logoutDevice', () => {
      socketReducer.logout();
      auth.logout()
    });

    this.socketInstance.on('stop_streaming', () => {
      socketReducer.stopSreaming()
    });

    this.socketInstance.on('error', async (data) => {
      if (data["error"] == 401) {
          const rs = await utils.refreshToken();
          const { token, newRefreshToken } = rs;
          if (token !== undefined && token !== null && token !== "") {
            utils.setCookie(token, newRefreshToken);
          }
          setTimeout(() => this.socketInstance.disconnect().connect(), 3000); 
      }
    })
  }

  onPlayStreaming() {
    if (!!this.socketInstance) {
      this.socketInstance.emit('streaming_start', {
        token: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
        fingerPrint: this.fingerprint,
        id: this.socketInstance.id
      })
    }
  }

}

const socket = new Socket();

export default socket;
