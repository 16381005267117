import utils from "../utils/utils";
import instance from "./service";
import constant from "../utils/constant";
import favoritesReducer from "../reducers/favoritesReducer";
import multiSpotReducer from "../reducers/multiSpotReducer";

class UserService {
  async handleFavorites(favorites) {
    const spots = utils.formatListToString(favorites);
    let res = await instance.post(
      "/user/favorites",
      { favorites: spots },
      {
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH),
      },
    );
    multiSpotReducer.saveSpots(spots);
    favoritesReducer.saveSpots(spots);
    return res?.status === 200;
  }

  async getPublicUser(publicId) {
    const publicUser = await instance.get("/getPublicUser", {
      params: { publicId: publicId },
    });
    return publicUser;
  }
}

const userService = new UserService();

export default userService;
