import instance from "./service";

class BenefitService {
  async getBenefits() {
    let result = null;
    try {
      result = await instance.get(`/getBenefits`);
      console.log(result)
    } catch (error) {
      result = error;
    }
    return result;
  }

  async getNearBenefits(spotCoordinates) {
    const resultResponse = await instance.post("/getNearBenefits", {spotCoordinates});

    return resultResponse;
  }
}

const benefitService = new BenefitService();

export default benefitService;
