import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TestimonialComponent = (props) => {
    let { country } = props;
    const { t, i18n } = useTranslation();
    
    let riderFemaleImage = null;
    let riderFemaleQuote = null;
    let riderFemaleName = null;
    let riderMaleImage = null;
    let riderMaleQuote = null;
    let riderMaleName = null;

    if (country === "AR") {
      riderFemaleImage = "luchy-cosoleto.png";
      riderFemaleQuote = t("testimonial_rider_luchy_quote");
      riderFemaleName = t("testimonial_rider_luchy_name");
      riderMaleImage = "nacho-gundesen.png";
      riderMaleQuote = t("testimonial_rider_nacho_quote");
      riderMaleName = t("testimonial_rider_nacho_name");
    } else {
      // Default to Uruguay
      riderFemaleImage = "delfi-morosini.png";
      riderFemaleQuote = t("testimonial_rider_delfi_quote");
      riderFemaleName = t("testimonial_rider_delfi_name");
      riderMaleImage = "luki-madrid.png";
      riderMaleQuote = t("testimonial_rider_luki_quote");
      riderMaleName = t("testimonial_rider_luki_name");
    }
  
    return (
        <Testimonial className="clean-style">
            <h2 className="offset-lg-2 clean-style" id="scrollToView">{t("testimonial_title")}{" "}</h2>
            <div className="row clean-style">
                <CardSurfer className="offset-lg-2 col-lg-4 clean-style">
                    <img className="clean-style" src={require("../assets/riders/" + riderFemaleImage)} alt={ riderFemaleName } />
                    <TextSurfer className="clean-style">
                        <p className="clean-style">{ riderFemaleQuote }</p>
                        <h3 className="clean-style">-&nbsp;{ riderFemaleName }</h3>
                    </TextSurfer>
                </CardSurfer>
                <CardSurfer className=" col-lg-4 clean-style">
                    <img className="clean-style" src={require("../assets/riders/" + riderMaleImage)} alt={ riderMaleName } />
                    <TextSurfer className="clean-style">
                        <p className="clean-style">{ riderMaleQuote }</p>
                        <h3 className="clean-style">-&nbsp;{ riderMaleName }</h3>
                    </TextSurfer>
                </CardSurfer>
            </div>
            <Talkabout className="clean-style">
                <h3 className="clean-style">{t("talk_about_us")}</h3>
                <img className="clean-style" src={require("../assets/icons/Yahoo.png")} alt={"Yahoo"} />
                <img className="clean-style" src={require("../assets/icons/dukesurf.png")} alt={"Duke Surf"} />
                <img className="clean-style" src={require("../assets/icons/el-pais-uruguay.png")} alt={"El País Uruguay"} style={country === "UY" ? {} : { display: "none" }} />
                <img className="clean-style" src={require("../assets/icons/surfistamag.png")} alt={"SurfistaMAG"} style={country === "AR" ? {} : { display: "none" }} />
            </Talkabout>
        </Testimonial>
    );
};

const TextSurfer = styled.div`
  display: grid !important;
  p{
    font-size: 14.4px !important; 
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
    letter-spacing: initial;
  }

  h3{
    font-size: 19.3px !important;
  }
`;

const Testimonial = styled.div`
  width: 100% !important;
  background-color: #fabc41 !important;
  min-height: 350px !important;
  margin: 30px 0px !important;

  h2 {
    padding-left: 10px !important;
    padding-top: 30px !important;
    font-weight: 700 !important;
    color: #000 !important;
    margin-bottom: 0px !important;

    @media (max-width: 1000px) {
      padding-left: 0px !important;
      margin-left: 0px !important;
      text-align: center !important;
      font-size: 1.2em !important;
      margin-bottom: 20px !important;
    }
  }

  .row {
    @media (max-width: 1000px) {
      padding-bottom: 40px !important;
      display: flex !important;
      flex-wrap: wrap !important;
      margin-right: -15px !important;
      margin-left: -15px !important;
      --bs-gutter-x: 1.5rem !important;
      --bs-gutter-y: 0 !important;
    }
  }
`;

const Talkabout = styled.div`
  display: flex !important;
  align-items: center !important;
  margin-left: 16.666667% !important;
  padding-bottom: 30px !important;
  @media (max-width: 1000px) {
    flex-direction: column !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }

  h3 {
    font-size: 19px !important;
    font-weight: 800 !important;
    width: fit-content !important;
    color: #000 !important;
  }

  img {
    width: 100px !important;
    height: auto !important;
    margin: 10px 30px !important;
    margin-right: 0 !important;
  }
`;

const CardSurfer = styled.div`
  display: flex !important;
  align-items: flex-start !important;
  background-color: unset !important;
  box-shadow: none !important;
  margin-top: 30px !important;
  padding: 20px !important;
  margin-bottom: 30px !important;

  @media (max-width: 1000px) {
    margin-bottom: 5px !important;
    padding: 0px 30px !important;
    margin-top: 5px !important;
  }

  p {
    margin-left: 30px !important;
    font-size: 16px !important;
    margin-top: 30px !important;
    color: #000 !important;
    font-weight: 500 !important;

    @media (max-width: 1000px) {
      margin-left: 20px !important;
      font-size: 16px !important;
      font-weight: normal!important;
      margin-top: 20px !important;
      margin-bottom: 8px !important;
    }
  }

  h3 {
    margin-left: 30px !important;
    // font-size: 1.2em !important;
    font-weight: 800 !important;
    color: #000 !important;

    @media (max-width: 1000px) {
      margin-left: 20px !important;
      font-size: 0.9em !important;
    }
  }

  img {
    border-radius: 5px !important;
    margin-top: 20px !important;
    width: 150px !important;

    @media (max-width: 1000px) {
      width: 100px !important;
    }
  }
`;

const mapStateToProps = (state) => ({
  country: state.country
});

export default connect(mapStateToProps)(TestimonialComponent);