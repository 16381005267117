//import React from 'react';
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { connect } from 'react-redux';
import WaveQualityComponent from "./waveQualityComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faWind, faDroplet, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import utils from '../utils/utils';
import ClockComponent from './clockComponent';
import useHandleDirection from '../hooks/useHandleDirection';
import constant from '../utils/constant';
import { Tooltip } from 'reactstrap';
import WaveHeightTooltip from "./waveHeightTooltip";

const ForecastNow = (props) => {
  const { t } = useTranslation();
  const { forcastDay, timezone, currentTime, spot, lan, type } = props;
  const { rating, swellOrientation, swellPeriod, waveHeight, secondarySwellHeight, swellPeriod2, swellOrientation2, windSpeed, windOrientation, windType, waterTemperature, airTemperature } = forcastDay[currentTime];
  const { handleDirection } = useHandleDirection(lan);
  const { BEACH, LAKE } = constant.SPOT_TYPE;
  const { swell_favorable, swell_optimum, max_wave_height, country_code } = spot;
  const [isMobile, setIsMobile] = useState(false);
  const [helpIsOpen, setHelpIsOpen] = useState(false);

  const handleLycra = (waterTemperature) => {
    let result;
    switch (true) {
      case (waterTemperature >= 26):
        result = <p>Lycra UV </p>;
        break;
      case (23 <= waterTemperature && waterTemperature <= 25):
        result = <p>1 <span>mm</span></p>;
        break;
      case (20 <= waterTemperature && waterTemperature <= 22):
        result = <p>2 <span>mm</span></p>;
        break;
      case (16 <= waterTemperature && waterTemperature <= 19):
        result = <p>3/2 <span>mm</span></p>;
        break;
      case (8 <= waterTemperature && waterTemperature <= 15):
        result = <p>4/3 <span>mm</span></p>;
        break;
      case (4 <= waterTemperature && waterTemperature <= 7):
        result = <p>5/4 <span>mm</span></p>;
        break;
      default:
        result = <p>6/5 <span>mm</span></p>;
        break;
    }

    return result;
  }

  handleLycra(waterTemperature);
  return (
    <>
    <Divider style={{marginBottom: '0'}}/>
    <Container beach={type !== LAKE} country={country_code}>

      <h2 className="forecast-now-title"></h2>

      {/* titles */}
      {type !== LAKE && (
        <>
          <h2 className='rating-title'>{t("rating")}</h2>
          <h2 className='swell-title'>{t("swell")}</h2>
        </>
      )}
      <h2 className='wind-title'>{t("wind")}</h2>
      <h2 className='temperature-title'>{t("temperature")}</h2>
      {country_code != 'BR' && <h2 className='suit-title'>{t("suggest_suit")}</h2>}
      <h2 className='clock-title'>{t("clock_text")}</h2>
      {/* Forcast data */}
      {type !== LAKE && (
        <>
          <div className='rating-row f-row'><Border /><WaveQualityComponent rating={rating} /></div>
          <div className='swell-row f-row'>
            <Border />
            <SwellRow color="#000">
              <NumberData>1</NumberData>
              <p>
                {waveHeight} <span id='yeah'>m</span>
                {}
                {max_wave_height < waveHeight ? <> <FontAwesomeIcon id='warning1' icon={faExclamationTriangle} /><WaveHeightTooltip index={"warning1"} />
              </> : ""}
              </p>
              
              <p>{swellPeriod} <span>s</span> </p>
              <p>{handleDirection(swellOrientation)}</p>
              <FontAwesomeIcon icon={faLocationArrow} className={`arrow-direction-${swellOrientation} swell-direction-${utils.swellQuality(swell_favorable, swell_optimum, swellOrientation)}`} />
            </SwellRow>
            <SwellRow color="#828080">
              <NumberData>2</NumberData>
              <p>{secondarySwellHeight} <span>m </span> 
              {max_wave_height < secondarySwellHeight ? <><FontAwesomeIcon id="warning2" icon={faExclamationTriangle} /><WaveHeightTooltip index={"warning2"} /></> : ""}
              </p>
              
              <p>{swellPeriod2} <span>s</span> </p>
              <p>{handleDirection(swellOrientation2)}</p>
              <FontAwesomeIcon icon={faLocationArrow} className={`arrow-direction-${swellOrientation2} swell-direction-${utils.swellQuality(swell_favorable, swell_optimum, swellOrientation2)}`} />
            </SwellRow>
          </div>
        </>
      )}

      <WindRow className='wind-row f-row'>
        <Border />
        <div className='wind-row'>
          <p>{windSpeed} <span>km/h</span></p>
          <p>{handleDirection(windOrientation)}</p>
          <FontAwesomeIcon icon={faLocationArrow} className={`arrow-direction-${windOrientation} ${type !== LAKE && (utils.formatWind(windType))} `} />
        </div>
        {type !== LAKE && <div className={`${utils.formatWind(windType)}`} style={{ fontWeight: 500 }}>{utils.formatWind(windType)}</div>}
      </WindRow>

      <TemperatureRow className='temperature-row f-row'>
        <Border />
        { country_code != 'BR' && (
            <div>
            <FontAwesomeIcon icon={faDroplet} />
            <p>{waterTemperature} °C</p>
          </div>
        )}
        <div>
          <FontAwesomeIcon icon={faWind} />
          <p>{airTemperature} °C</p>
        </div>
      </TemperatureRow>
      { country_code != 'BR' && (
        <div className='suit-row f-row'>
          <Border />
          {handleLycra(waterTemperature)}
        </div>
      )}
      <div className='clock-row f-row'>
        <Border />
        <ClockComponent timezone={timezone} />
      </div>
    </Container>
    <Divider style={{marginTop: '0'}}/>
    </>
  )
}

const Divider = styled.hr`
  color: #e4e7ed;
  height: 1px;

  @media(max-width: 1000px){ display: none; }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.beach ? ( props.country == 'BR' ? 'repeat(5, max-content)' : 'repeat(6, max-content)' ) : ( props.country == 'BR' ? 'repeat(3, max-content)' : 'repeat(4, max-content)' )};
  min-width: 100%;
  justify-content: space-evenly;
  margin: 0 auto;

  .forecast-now-title, .forecast-now-footer{
    grid-column: span  ${(props) => props.beach ? ( props.country == 'BR' ? '5' : '6' ) : ( props.country == 'BR' ? '3' : '4' )};
    font-size: 18px;
    // border-bottom: 1px solid #e4e7ed;
    padding-bottom: 10px;
  }

  h2{
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #212529;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    padding: 0 10px;
  }

  p{
    color: #000;
    font-size: 18px;
    margin: 0;
    span{
      font-size: 14.4px;
      margin-right: 5px;
      color: #828080;
    }  
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    line-height: 1.2;
  }

  svg{ width: 16px; }

  .f-row{
    position: relative;
  }

  > .f-row{
    padding: 10px 20px;
  }

  .f-row:nth-of-type(1) div:nth-of-type(1){ display: none; }

  @media(max-width: 999px){
    width: 100%;
    grid-template-columns: .5fr 1.5fr;
    grid-template-rows: auto;
    grid-template-areas:
      "header header"
      "rating rate"
      "swell swell-data"
      "wind wind-data"
      "temperature temp-data"
      "suggest suit"
      "time clock";
      .f-row:nth-of-type(1) div:nth-of-type(1){ display: flex; }

    .forecast-now-footer{ border-bottom: none; }

    >h2{
      border-bottom: 1px solid #e4e7ed;
      margin: 0;
    }

    > h2{
      text-align:start;
      display: flex;
      align-items: center;
    }

    #forecast-now-title{
      gird-column: span 2;
      grid-area: header;
      text-align: center;
    }

    > .rating-title{
      grid-area: rating;
    }
    > .swell-title{
      grid-area: swell;
    }
    > .wind-title{
      grid-area: wind;
    }
    > .temperature-title{
      grid-area: temperature;
    }
    > .suit-title{
      grid-area: suggest;
    }
    > .clock-title{
      grid-area: time;
    }

    >.f-row{
      padding-left: 15%;
      border-bottom:  1px solid #e4e7ed;
    }

    > .rating-row{
      grid-area: rate;

    }
    > .swell-row{
      grid-area: swell-data;
    }
    > .wind-row{
      grid-area: wind-data;
    }
    > .temperature-row{
      grid-area: temp-data;
      flex-direction: row;
    }
    > .suit-row{
      grid-area: suit;
    }
    > .clock-row{
      grid-area: clock;
      p{
        text-align: start;
      }
    }
    
  }
`

const SwellRow = styled.div`
  display: flex;

  p{
    color: ${(props) => props.color};
    font-size: 18px;
    margin: 0;
    span{
      font-size: 14.4px;
      margin-right: 5px;
      color: #828080;
    }  
  }
  p:nth-of-type(1){
    width: 6ch;
  }

  p:nth-of-type(2){
    width: 4ch;
  }

  p:nth-of-type(3){
    width: 3ch;
  }
`

const NumberData = styled.span`
  padding: 1px 5px;
  font-size: 0.7em;
  border-radius: 3px;
  margin-right: 10px;
  color: #fff;
  background: #828080;
  height: min-content;
`;

const Border = styled.div`
  border-left: 1px solid #e4e7ed;
  bottom: 5px;
  left: 0;
  position: absolute;
  top: -25px;
  width: 0;

  @media(max-width: 999px){
    top: 5px;
  }
`

const WindRow = styled.div`
  display: flex;
  flex-direction: column;

  p{
    color: #000;
    font-size: 18px;
    margin: 0;
    width: 7.5ch;
    span{
      font-size: 14.4px;
      margin-right: 5px;
      color: #828080;
    }
      
  }

  > div{
    display: flex;
    p:nth-of-type(2){
      width: 3ch;
    }
  }

  td:nth-of-type(2) p:nth-of-type(1){
    width: max-content;
    font-size: 18px;
  }

  svg{
    margin-right: 10px;
  }

`

const TemperatureRow = styled.div`
    display: flex;
    flex-direction: column;

    > div{ display: flex; }

    p{
        color: #000;
        font-size: 18px;
        margin: 0;
        width: 6ch;

        span{
            font-size: 14.4px;
            margin-right: 5px;
            color: #828080;
        }

        
    }

    svg{
        margin-right: 10px;
    }

`

const mapStateToProps = (state) => (
  {
    currentTime: state.currentTime,
    lan: state.lan
  }
)

export default connect(mapStateToProps)(ForecastNow);