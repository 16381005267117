import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import utils from '../utils/utils';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faWind, faDroplet, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import WaveQualityComponent from './waveQualityComponent';
import { useTranslation, Trans } from 'react-i18next';
import useHandleDirection from '../hooks/useHandleDirection';
import constant from '../utils/constant';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import WaveHeightTooltip from './waveHeightTooltip';

const ForecastTable = (props) => {
    const { spot, user, lan, currentTime } = props;
    const { data: spotData, swell_favorable, swell_optimum } = spot;
    const { isPremium } = user;
    const [forcastDays, setForcastDays] = useState([]);
    const { t, i18n } = useTranslation();
    const { handleDirection } = useHandleDirection(lan);
    useEffect(() => {
        formatDays(spotData);
    }, [])
    const { BEACH, LAKE } = constant.SPOT_TYPE;
    const tableClass = spot.spot_type !== LAKE ? 'new-forecast-table' : 'forecast-table-lakes';
    const [isMobile, setIsMobile] = useState(false);
    const [helpIsOpen, setHelpIsOpen] = useState(false);

    const formatDays = (data) => {
        let result = [];
        let index = 0;
        Object.keys(data).forEach(function (key) {
            let currentDate = new Date(key);
            currentDate.setDate(currentDate.getDate() + 1);
            let dayAndHour = " " + currentDate.getDate() + "/" + (currentDate.getMonth() + 1);
            let currentDateName = currentDate.toLocaleString(i18n.language, { weekday: "long" });
            let currentDateValue = currentDateName + dayAndHour;
            if (index === 0 && currentDate.getDate() == new Date().getDate()) {
                currentDateValue = t("_TODAY") + ", " + currentDateName + dayAndHour;
            }
            if (index === 1 && currentDate.getDate() == new Date().getDate() + 1) {
                currentDateValue = t("_TOMORROW") + ", " + currentDateName + dayAndHour;
            }
            result.push(currentDateValue);
            index = index + 1;
        });
        setForcastDays(result);
    };

    return (
        <>
            <section className='table-container' id="forecast-data">
                <section className={tableClass}>
                    <div></div>
                    {spot.spot_type !== LAKE && (
                        <>
                            <div><Border />{t("rating")}</div>
                            <div><Border />{t("swell")}<NumberData>1</NumberData></div>
                            <div><Border />{t("swell")}<NumberData>2</NumberData></div>
                        </>
                    )}
                    <div><Border />{t("wind")}</div>
                    <div><Border />{t("temperature")}</div>
                    {
                        Object.keys(spotData).map((day, dayIndex) => {
                            if (dayIndex === 0) {
                                spotData[day][currentTime].actual = true;
                            }
                            let times = [spotData[day].morning, spotData[day].midday, spotData[day].afternoon]
                            let needBlur = dayIndex > 2 && !isPremium;

                            return (
                                <> {!needBlur && <>
                                    <>
                                        <div className='day'>{forcastDays[dayIndex]}</div>
                                    </>
                                    {
                                        times.map((time, index) => {
                                            let data = time;
                                            let { swellOrientation, swellOrientation2 } = data;
                                            if (needBlur) {
                                                swellOrientation = "S";
                                                swellOrientation2 = "S"
                                            }
                                            const tooltipId = Date.now() + Math.random(2).toString().slice(2, 11);

                                            return (
                                                <>

                                                    <div className={`forecast-time ${needBlur ? "" : ""} ${data?.actual ? "actual" : ""}`}>
                                                        {/* {needBlur && <Blur />} */}
                                                        {
                                                            index == 0 ? <><span>6</span> <span>am</span></> :
                                                                index == 1 ? <><span>12</span> <span>pm</span></> : <><span>6</span> <span>pm</span></>
                                                        }
                                                    </div>
                                                    {spot.spot_type !== LAKE && (
                                                        <>

                                                            <div className={`${needBlur ? "" : ""} ${data?.actual ? "actual" : ""} justify-content-center`}>{needBlur && <Blur />}<Border /> <WaveQualityComponent rating={needBlur ? 3 : data.rating} /></div>
                                                            <SwellRow color='#000' className={`${needBlur ? "" : ""} ${data?.actual ? "actual" : ""}`}>
                                                                {/* {needBlur && <Blur />} */}
                                                                <Border />

                                                                <p>{needBlur ? 0.0 : data.waveHeight} <span>m</span>
                                                                {spot.max_wave_height < data.waveHeight ? <><FontAwesomeIcon id={`warningSwell1-${tooltipId}`} icon={faExclamationTriangle} />
                                                                    <WaveHeightTooltip index={`warningSwell1-${tooltipId}`} />
                                                                </> : ""}
                                                                </p>
                                                                <p>{needBlur ? 0.0 : data.swellPeriod} <span>s</span> </p>
                                                                <p>{needBlur ? "S" : handleDirection(data.swellOrientation)}</p>
                                                                <FontAwesomeIcon icon={faLocationArrow} className={`arrow-direction-${swellOrientation} swell-direction-${utils.swellQuality(swell_favorable, swell_optimum, swellOrientation)}`} />
                                                            </SwellRow>
                                                            <SwellRow color='#828080' className={`${needBlur ? "" : ""} ${data?.actual ? "actual" : ""}`}>
                                                                {/* {needBlur && <Blur />} */}
                                                                <Border />
                                                                <p>
                                                                    {needBlur ? 0.0 : data.secondarySwellHeight} <span>m </span>
                                                                    {spot.max_wave_height < data.secondarySwellHeight ? <>
                                                                    <FontAwesomeIcon id={`warningSwell2-${tooltipId}`} icon={faExclamationTriangle} /><WaveHeightTooltip index={`warningSwell2-${tooltipId}`} /> </> : ""}
                                                                </p>
                                                                
                                                                <p>{needBlur ? 0.0 : data.swellPeriod2} <span>s</span> </p>
                                                                <p>{needBlur ? "S" : handleDirection(data.swellOrientation2)}</p>
                                                                <FontAwesomeIcon icon={faLocationArrow} className={`arrow-direction-${swellOrientation2} swell-direction-${utils.swellQuality(swell_favorable, swell_optimum, swellOrientation2)}`} />
                                                            </SwellRow>

                                                        </>
                                                    )}
                                                    <WindRow className={`${needBlur ? "" : ""} ${data?.actual ? "actual" : ""}`}>
                                                        {/* {needBlur && <Blur />} */}
                                                        <Border />
                                                        <p>{needBlur ? 0.0 : data.windSpeed} <span>km/h</span></p>
                                                        <p>{needBlur ? "S" : handleDirection(data.windOrientation)}</p>
                                                        <FontAwesomeIcon icon={faLocationArrow} className={`arrow-direction-${needBlur ? "S" : data.windOrientation} ${spot.spot_type !== LAKE && (needBlur ? "cruzado" : utils.formatWind(data.windType))}`} />
                                                        {spot.spot_type !== LAKE && <p className={`${needBlur ? "cruzado" : utils.formatWind(data.windType)}`}>{needBlur ? "---" : utils.formatWind(data.windType)}</p>}
                                                    </WindRow>
                                                    <TemperatureRow className={`${needBlur ? "" : ""} ${data?.actual ? "actual" : ""}`}>
                                                        {/* {needBlur && <Blur />} */}
                                                        <Border />
                                                        <FontAwesomeIcon icon={faWind} />
                                                        <p>{needBlur ? 0.0 : data.airTemperature} °C</p>
                                                    </TemperatureRow>

                                                </>
                                            )
                                        })
                                    }
                                </>}</>
                            )
                        })
                    }
                </section>
            </section>
            {!isPremium &&
                <>
                    <PremiumAdd>
                        <h1>{t("Pronósticos exclusivos para Premium")}</h1>
                        <p>
                            <Trans
                                i18nKey="forecast_limited_modal_description" // optional -> fallbacks to defaults if not provided
                                values={{ spotName: spot.name }}
                                components={{ italic: <i />, bold: <strong /> }}
                            />
                        </p>
                        <div className='premium-button-box'>
                            <Link to={{ pathname: "/premium", state: { prevPath: window.location.pathname, isRegister: true } }}>{t("forecaster_prono_premium_alert_button")}</Link>
                        </div>
                    </PremiumAdd>
                    <hr style={{ margin: "2em 0 1m 0", background: "#e4e7ed" }}></hr>
                </>
            }
        </>
    )
}

const Border = styled.div`
    border-left: 1px solid #e4e7ed !important;
    bottom: 5px !important;
    left: 0 !important;
    position: absolute !important;
    top: 5px !important;
    width: 0 !important;
    padding: 0 !important;
    margin: 0
`

const SwellRow = styled.div`
  display: flex;

  p{
    color: ${(props) => props.color};
    font-size: 18px;
    margin: 0;
    span{
      font-size: 14.4px;
      margin-right: 4px;
      color: #828080;
    }  
  }
  p:nth-of-type(1){
    width: 6ch;
  }

  p:nth-of-type(2){
    width: 4ch;
  }

  p:nth-of-type(3){
    width: 3ch;
  }
`


const NumberData = styled.span`
  padding: 1px 5px;
  font-size: 0.7em;
  border-radius: 3px;
  margin-left: 5px;
  color: #fff;
  background: #828080;
`;

const WindRow = styled.div`
    display: flex;

    p{
        color: #000;
        font-size: 18px;
        margin: 0;
        width: 7.5ch;
    }

    p:nth-of-type(2){
        width: 3ch;
    }

    p:nth-of-type(3){
        width: max-content;
        font-size: 14.4px;
    }

    svg{
        margin-right: 10px;
    }

`

const TemperatureRow = styled.div`
    display: flex;

    p{
        color: #000;
        font-size: 18px;
        margin: 0;
        width: 6ch;

        span{
            font-size: 14.4px;
            margin-right: 5px;
            color: #828080;
        }

        
    }

    svg{
        margin-right: 10px;
    }

`

const Blur = styled.section`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    z-index: 999;
`

const PremiumAdd = styled.div`
    margin: 10px auto;
    padding: 15px 5px;
    border-radius: 5px;
    // border: 3px solid #dee2e6;
    width: 70%;

    h1{
        text-align: center;
        font-size: 1.7em;
        font-weight: bold;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        color: #000;
    }

    p{
        font-size: 1.3em;
        text-align: center;
        width: 60%;
        margin: auto;
    }

    a{
        background-color: #32c943;
        border-radius: 5px;
        color: #fff;
        margin: auto;
        padding: 8px 25px !important;
        font-size: 0.9em;
    }

    div{
        margin: 10px auto 0 auto;
        width: fit-content;
    }


    @media(max-width: 999px){
        width: 100%;

        h1{
            font-size: 1.5em;
        }

        p{
            font-size: 1.1em !important;
            width: 100%;
        }
    }
`

const mapStateToProps = (state) => ({
    user: state.user,
    currentTime: state.currentTime,
    lan: state.lan
})

export default connect(mapStateToProps)(ForecastTable)