import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

const DateTimeDisplay = ({ value, type, isDanger }) => {
    return (
      <div className={isDanger ? 'countdown danger' : 'countdown'}>
        <p>{value}</p>
        <span>{type}</span>
      </div>
    );
  };


const ShowCounter = ({ days, hours, minutes, seconds }) => {
    const { t } = useTranslation();

    return (
      <div className="show-counter">
        <div
          className="countdown-link"
        >
          <DateTimeDisplay value={days} type={t("_DAYS")} isDanger={days <= 3} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={t("_HOURS")}isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={t("_MINUTOS")} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={t("_SECONDS")} isDanger={false} />
        </div>
      </div>
    );
  };

  export default ShowCounter