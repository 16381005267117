import store from "../utils/store";

class SponsorsReducer {

  saveSponsors(sponsors) {
    store.dispatch({
      type: "SPONSORS",
      data: sponsors,
    });
  }
}

const sponsorsReducer = new SponsorsReducer();

export default sponsorsReducer;