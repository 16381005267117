import store from "../utils/store";

class StreamingReducer {

  saveTemporal(freeTime, paidTime) {
    store.dispatch({
      type: "HANDLE_STREAMING_TIME",
      data: {
        freeTime: freeTime,
        paidTime: paidTime
      }
    });
  }
}

const streamingReducer = new StreamingReducer();

export default streamingReducer;