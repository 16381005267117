import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import store from "../utils/store";
import { Fade } from "reactstrap";
import animationData from "../assets/animations/surf.json";
import Lottie from "react-lottie";

const FreeWallPremium = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.showModalToFree === true) {
      setIsOpen(true);
      store.dispatch({
        type: "SHOW_PREMIUM_TO_FREE",
        data: false,
      });
    }
  }, [props.showModalToFree]);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const goBack = () => {
    setIsOpen(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      {isOpen === true && (
        <PopUpContainer className="modal-open">
          <Fade>
            <PopUpBox>
              <PopUpOverlay />
              <Lottie options={defaultOptions}
                height={130}
                width={100}
                style={{paddingTop: '1em'}} />
              <PopUpTitle>{t("free_modal_premium_title")}</PopUpTitle>
              <ContainerBoxInfo>
                <BoxInfo>
                  <img crossOrigin="anonymous"src={require("../assets/icons/cam_white.svg")} alt="camrewind" />
                  <div>
                    <h2>{t("free_modal_premium_cam_title")}</h2>
                    <p>{t("free_modal_premium_cam_description")}</p>
                  </div>
                </BoxInfo>
                <BoxInfo>
                  <img crossOrigin="anonymous"src={require("../assets/icons/camrewind.svg")} alt="camrewind" />
                  <div>
                    <h2>{t("free_modal_premium_rewind_title")}</h2>
                    <p>{t("free_modal_premium_rewind_description")}</p>
                  </div>
                </BoxInfo>{" "}
                <BoxInfo>
                  <img crossOrigin="anonymous"src={require("../assets/icons/multispot.svg")} alt="camrewind" />
                  <div>
                    <h2>{t("free_modal_premium_multispot_title")}</h2>
                    <p>{t("free_modal_premium_multispot_description")}</p>
                  </div>
                </BoxInfo>{" "}
                <BoxInfo>
                  <img crossOrigin="anonymous"src={require("../assets/icons/forecast_white.svg")} alt="camrewind" />
                  <div>
                    <h2>{t("free_modal_premium_forecast_title")}</h2>
                    <p>{t("free_modal_premium_forecast_description")}</p>
                  </div>
                </BoxInfo>

                {/* <RidersBanner>
                  <RidersBox>
                    <img src={require("../assets/riders/luki.png")}></img>
                    <img src={require("../assets/riders/delfi.png")}></img>
                  </RidersBox>
                  <p>{t("free_modal_premium_riders")}</p>
                </RidersBanner> */}

                {props.disableButton !== true && (
                <PopUpButtonContainer>
                  <PopUpButton>
                    <Link to="/premium" onClick={() => setIsOpen(false)}>
                      {props.user && props.user.canUseFreeTrial === true ? t("free_modal_premium_button_free_trial") : t("free_modal_premium_button")}
                    </Link>
                  </PopUpButton>
                  <PopUpButtonClose onClick={() => setIsOpen(false)}>
                    <Link>{t("free_modal_premium_cancel_button")}</Link>
                  </PopUpButtonClose>
                 
                </PopUpButtonContainer>
              )}

              </ContainerBoxInfo>

            </PopUpBox>
          </Fade>
        </PopUpContainer>
      )}
    </>
  );
};

const PopUpBox = styled.div`
  background-position-x: center !important;
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 4em auto;
  background: #EEEDEF;
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  text-align: center;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-backface-visibility: hidden;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  background-size: cover;

  @media (max-width: 700px) {
    min-height: 100vh;
    margin: 0;
    border-radius: 0;
    transform: translateY(0);
    top: 0;
    max-width: 100%;
  }
`;

const PopUpOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -9;
`;

const PopUpTitle = styled.h1`
  font-size: 1.2em;
  font-weight: 600;
  padding: 1em;
  padding-bottom: 0px;
  font-family: "Poppins";
  color: #000;

  @media (max-width: 700px) {
    font-size: 1em;
    font-weight: 600;
  }
`;

const ContainerBoxInfo = styled.div`
  width: 100%;
  padding: 5px 20px;
`;

const BoxInfo = styled.div`
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  text-align: left;
  width: 100%;
  margin: 5px 0px;
  min-height: 6.2em;

  div {
    align-self: center;
  }

  h2,
  p {
    color: #000;
    font-size: 1.2em;
    margin-left: 15px;
    margin-bottom: 0px;
  }

  h2 {
    font-size: 1.1em;
  }
  p {
    font-size: 0.9em;
    margin-top: 5px;
  }

  img {
    width: 42px;
    height: 42px;
    margin-top 20px;
    filter: invert(1);
  }

  @media (max-width: 700px) {
    min-height: 6em;


    h1,
    h2 {
      font-size: 1em;
      font-weight: 700;
    }

    p {
      font-size: 0.8em;
    }
  }
`;

const RidersBanner = styled.div`
  background-color: #d5dbe7;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  text-align: left;
  width: 100%;
  margin: 5px 0px;
  height: 4em;
  justify-content: center;
  align-items: center;

  p{
    margin: 0;
    color: #000;
    font-weight: bold;
  }
`

const RidersBox = styled.div`
  height: 100%;
  position: relative;
  display: inline-flex;
  margin-right: 25px;

  img{
    width: auto;
    height: 70%;
    border-radius: 50px;
  }

  img:nth-of-type(1){
    margin: auto;
  }

  img:nth-of-type(2){

    margin: auto;
    margin-left: -11px;
  }

  @media (max-width: 700px) {
    margin-right: 35px;
  }
`

const PopUpButtonContainer = styled.ul`
  padding: 0 !important;
  color: #fff;
  margin-bottom: 0px;

  li:first-child button {
    background: #31c241;
    border: none;
    width: 100%;
    border-radius: 0 0 0.25em 0.25em;
  }
`;

const PopUpButton = styled.li`
  width: 100% important;
  list-style: none !important;
  list-style-type: none !important;
  a {
    display: block;
    color: #fff;
    transition: background-color 0.2s;
    background-color: #31c241;
    font-size: 1.1em;
    font-weight: 600;
    border-radius: 5px;
    margin: 5px 0;
    padding: 12px;
  }
`;

const PopUpButtonClose = styled.li`
  width: 100% important;
  list-style: none !important;
  list-style-type: none !important;
  a {
    display: block;
    background-color: #000000;
    height: 40px !important;
    line-height: 40px !important;
    font-size: 0.8em;
    color: #fff;
    transition: background-color 0.2s;
    font-weight: bold;
    border-radius: 5px;
    margin: 8px 0;
  }
`;

const PopUpContainer = styled.div`
  overflow-x: hidden !important;
  overflow-y: auto !important;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;
  z-index: 999999999999999999999 !important;
  video {
    border-radius: 3px !important;
  }

  @media (max-width: 700px) {
    overflow: auto;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  showModalToFree: state.showModalToFree,
});

export default connect(mapStateToProps)(FreeWallPremium);
