import axios from "axios";

class GeoLocationService {
    async getGeoLocationInfo() {
        return await axios({
            method: "get",
            url: "https://ipwhois.pro/?key=" + process.env.REACT_APP_GEOLOCATION_KEY,
            headers: {
                Accept: "application/json",
            }
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return null;
        });
    }
}

const geoLocationService = new GeoLocationService();

export default geoLocationService;