import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Table } from "reactstrap";
import { Tooltip } from "reactstrap";
import { AiFillWarning } from "react-icons/ai";
import utils from "../utils/utils";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import ShowMoreText from "react-show-more-text";
import ReactMarkdown from "react-markdown";
import WaveQualityComponent from "./waveQualityComponent";

const ForecastComponent = (props) => {
  const { t, i18n } = useTranslation();

  const [hourDay, setHourDay] = useState(0);
  const [isMobile, setIsMobile] = useState(utils.isUsingMobile());
  const [helpIsOpen, setHelpIsOpen] = useState(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (loading === false && props.selectCurrentHour === true) {
      const hours = new Date().getHours();
      setHourDay(hours < 11 ? 0 : hours < 17 ? 1 : 2);
      setLoading(true);
    }
  }, [hourDay]);

  return (
    <ForecastBox className={props.needBlur === true ? "blur" : ""} key={props.index}>
      <DateTitle>{props.day}</DateTitle>
      <ForecastNav>
        <ButtonTable scope="row" className={hourDay === 0 ? "left-top-radius active" : "left-top-radius"} onClick={() => setHourDay(0)} id="gtm-forecast-6-am">
          06:00
        </ButtonTable>
        <ButtonTable scope="row" className={hourDay === 1 ? "left-top-radius active" : "left-top-radius"} onClick={() => setHourDay(1)} id="gtm-forecast-12-pm">
          12:00
        </ButtonTable>
        <ButtonTable scope="row" className={hourDay === 2 ? "left-bottom-radius active" : "left-bottom-radius"} onClick={() => setHourDay(2)} id="gtm-forecast-6-pm">
          18:00
        </ButtonTable>
      </ForecastNav>
      {/* {props.isInterpreter === true ?
        <WaveQualityComponent rating={props.interpreterData[hourDay === 0 ? "morning" : hourDay === 1 ? "midday" : "afternoon"].rating} needBlur={props.needBlurRating}/>
        : <WaveQualityComponent rating={props.forecasting[hourDay === 0 ? "morning" : hourDay === 1 ? "midday" : "afternoon"].rating} needBlur={props.needBlurRating}/>
      } */}
      <Table bordered border="0">
        <ForecastContainer>
          {/* {props.isInterpreter === true ? ( */}
            <tr>
              <td>
                <ShowMoreText
                  /* Default options */
                  lines={5}
                  more="Mostras Más"
                  less="Mostrar Menos"
                  className="content-css interpreted-text"
                  anchorClass="my-anchor-css-class"
                  expanded={props.index === 0}
                  width={300}
                  truncatedEndingComponent={"... "}>
                  {utils.formatInterpreterText(props.interpreterData[hourDay === 0 ? "morning" : hourDay === 1 ? "midday" : "afternoon"][i18n.language]).map((value, index) => {
                    return <ReactMarkdown key={"markdon" + index}>{value}</ReactMarkdown>;
                  })}
                </ShowMoreText>
              </td>
            </tr>
          {/* ) : (
            <>
              <tr className="forecast-data-cell">
                <DataTable colspan="3" className="first-td" style={{ marginTop: "-12px" }}>
                  <DataTableBox>
                    <DataTableHelp className="left-padding top-padding">
                      <DataTitle>{t("wind")}: </DataTitle>{" "}
                      <DataHighlight>
                        {hourDay === 0 ? props.forecasting.morning.windSpeed : hourDay === 1 ? props.forecasting.midday.windSpeed : props.forecasting.afternoon.windSpeed}{" "}
                        <MeasureTable>km/h</MeasureTable>{" "}
                        {hourDay === 0 ? props.forecasting.morning.windOrientation : hourDay === 1 ? props.forecasting.midday.windOrientation : props.forecasting.afternoon.windOrientation}
                      </DataHighlight>
                    </DataTableHelp>
                  </DataTableBox>
                </DataTable>
                <td rowSpan="3" className="wave-forecast">
                  <h6>{t("forecast_wave_size")}</h6>
                  {hourDay === 0 && props.forecasting.morning.warning && props.forecasting.morning.warning.waveHeight && (
                    <>
                      <AiFillWarning id="forecastWarning" className="forecast-warning" />
                      <Tooltip placement={isMobile === true ? "top" : "left"} isOpen={helpIsOpen} target="forecastWarning" toggle={() => setHelpIsOpen(!helpIsOpen)}>
                        {t("forecast_issue")}
                      </Tooltip>
                    </>
                  )}
                  {hourDay === 1 && props.forecasting.midday.warning && props.forecasting.midday.warning.waveHeight && (
                    <>
                      <AiFillWarning id="forecastWarning" className="forecast-warning" />
                      <Tooltip placement={isMobile === true ? "top" : "left"} isOpen={helpIsOpen} target="forecastWarning" toggle={() => setHelpIsOpen(!helpIsOpen)}>
                        {t("forecast_issue")}
                      </Tooltip>
                    </>
                  )}
                  {hourDay === 2 && props.forecasting.afternoon.warning && props.forecasting.afternoon.warning.waveHeight && (
                    <>
                      <AiFillWarning id="forecastWarning" className="forecast-warning" />
                      <Tooltip placement={isMobile === true ? "top" : "left"} isOpen={helpIsOpen} target="forecastWarning" toggle={() => setHelpIsOpen(!helpIsOpen)}>
                        {t("forecast_issue")}
                      </Tooltip>
                    </>
                  )}
                  <h5>{hourDay === 0 ? props.forecasting.morning.waveHeight : hourDay === 1 ? props.forecasting.midday.waveHeight : props.forecasting.afternoon.waveHeight}m</h5>
                </td>
              </tr>
              <tr>
                <DataTable colspan="3" className="first-td">
                  <DataTableBox>
                    <DataTableHelp className="left-padding">
                      <DataTitle className="wind-type">
                        {hourDay === 0 ? props.forecasting.morning.windType : hourDay === 1 ? props.forecasting.midday.windType : props.forecasting.afternoon.windType}
                      </DataTitle>
                    </DataTableHelp>
                  </DataTableBox>
                </DataTable>
              </tr>
              <tr>
                <DataTable colspan="3" className="first-td">
                  <DataTableBox>
                    <DataTableHelp className="left-padding">
                      <DataTitle>
                        {t("swell")} <NumberData className="">1</NumberData>:
                      </DataTitle>
                      <DataHighlight>
                        {hourDay === 0 ? props.forecasting.morning.swellPeriod : hourDay === 1 ? props.forecasting.midday.swellPeriod : props.forecasting.afternoon.swellPeriod}s
                      </DataHighlight>{" "}
                      <DataHighlight>
                        {hourDay === 0 ? props.forecasting.morning.swellOrientation : hourDay === 1 ? props.forecasting.midday.swellOrientation : props.forecasting.afternoon.swellOrientation}
                      </DataHighlight>
                    </DataTableHelp>

                    <DataTableHelp className="left-padding">
                      <DataTitle>
                        {t("swell")} <NumberData className="">2</NumberData>:
                      </DataTitle>
                      <DataHighlight>
                        {hourDay === 0 ? props.forecasting.morning.swellPeriod2 : hourDay === 1 ? props.forecasting.midday.swellPeriod2 : props.forecasting.afternoon.swellPeriod2}s
                      </DataHighlight>{" "}
                      <DataHighlight>
                        {hourDay === 0 ? props.forecasting.morning.swellOrientation2 : hourDay === 1 ? props.forecasting.midday.swellOrientation2 : props.forecasting.afternoon.swellOrientation2}
                      </DataHighlight>
                    </DataTableHelp>
                  </DataTableBox>
                </DataTable>
              </tr>
            </>
          )} */}
        </ForecastContainer>
      </Table>
    </ForecastBox>
  );
};

const ForecastNav = styled.ul`
  display: flex;
  border-radius: 5px;
  width: 100%;
  padding-left: 0px;
  margin-bottom: 15px;
  padding: 0px 3px;

  li:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-left: 1px solid #425781;
  }

  li:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
`;

const ForecastBox = styled.div`
  table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 15px;
    width: 100%;
    border-width: 2px;
  }

  .post-title{
    font-size: 0.85em;
    margin-bottom: 10px !important;
    background-color: #4257810d;
    padding: 12px;
    border-radius: 4px;
    color: #203656 !important;
    margin: 0px 3px;
    margin-top: 7px !important;

    svg{
      width: 17px !important;
    }
  }

  @media (max-width: 385px) {

  .forecast-data-cell{
    .post-title{
      font-size: 0.85em;
    margin-bottom: 10px !important;
    background-color: transparent;
    padding: 8px;
    border-radius: 4px;
    color: #828080 !important;
    margin: 0px 3px;
    margin-top: 7px !important;
    margin-bottom: 25px !important;
    span{
      float: left;
    margin-top: 6px;
    margin-left: -2px;
    }
  }
  }
}

  .table td,
  .table th {
    padding: 7px 10px;
    vertical-align: middle !important;
  }

  .first-td {
    width: 100% !important;
  }

  .active {
    background-color: #425781 !important;
    color: #fff;
    border: 1px solid #425781;
  }
`;

const DataTitle = styled.h6`
  color: #828080 !important;
  font-size: 0.8em !important;
  text-align: left;
`;

const DataHighlight = styled.h6`
  font-weight: bold;
  font-size: 1em;
  color: black;
  margin-left: 5px;
`;


const ForecastContainer = styled.tbody`
  .wave-forecast {
    background-color: #1f78b4 !important;
    color: #fff;
    width: 10%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 7px 13px !important;
    padding-top: 15px !important;

    @media (max-width: 480px) {
      padding: 7px 14px !important;
    }

    h6 {
      font-size: 0.8em;
    }

    h5 {
      font-size: 1em;
    }
  }

  .left-top-radius {
    border-top-left-radius: 15px;
  }

  .left-bottom-radius {
    border-bottom-left-radius: 15px;
  }

  .left-padding {
    padding-left: 10px !important;
  }

  .top-padding {
    //padding-top: 7px !important;
    padding-top: 25px !important;
  }

  h5,
  h6 {
    color: #fff;
  }

  h6 {
    font-size: 0.85em;
  }

  h5 {
    font-weight: bold;
    margin-top: 0px;
  }
`;

const ButtonTable = styled.li`
  text-align: center;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 0.8em !important;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  vertical-align: middle;
  list-style: none;
  background-color: #fff !important;
  color: #425781;
  border-top: 1px solid #425781;
  border-bottom: 1px solid #425781;
  border-right: 1px solid #425781;

`;

const DataTableBox = styled.div`
  display: flex;

  @media (max-width: 345px) {
    display: grid;

    .left-padding {
      display: flex !important;
    }

    .left-padding:last-child {
      margin-top: 10px;
    }

    .wind-type {
      margin-top: -12px !important;
    }
  }
`;

const DataTableHelp = styled.div`
  display: inherit;
`;

const DataTable = styled.td`
  width: 90px;
  text-align: left;
  border-style: hidden !important;
  font-family: "Poppins", sans-serif;
  border-radius: 15px;
  font-size: 1em;
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
  display: flex;

  h6 {
    color: black;
  }

  @media (max-width: 1000px) {
    text-align: center;
    margin-right: 0px !important;
    width: 100%;
  }
`;

const MeasureTable = styled.span`
  font-size: 0.8em;
  margin-right: 5px;
  color: #828080;
`;

const NumberData = styled.span`
  background-color: #828080;
  padding: 1px 5px;
  font-size: 0.7em;
  border-radius: 3px;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: -30px;
  color: #fff;
`;

const DateTitle = styled.h1`
  font-size: 1em;
  margin: 20px 0px;
  padding: 10px 20px;
  padding-left: 0;
  font-weight: 700;
  text-transform: capitalize;
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  surfcams: state.surfcams,
});

export default connect(mapStateToProps)(React.memo(ForecastComponent));
