import store from "../utils/store";

class MultiSpotReducer {

  saveSpots(spots) {
    store.dispatch({
      type: "MULTISPOTS",
      data: spots,
    });
  }

  cleanSpots() {
    store.dispatch({
      type: "CLEAN_MULTISPOTS",
      data: null,
    });
  }
}

const multiSpotReducer = new MultiSpotReducer();

export default multiSpotReducer;