import store from "../utils/store";

class NotificationsReducer {

    updateNotifications(unreadCounter){
        store.dispatch({
            type: "NOTIFICATIONS",
            data: unreadCounter
        });
    }
}

const notificationsReducer = new NotificationsReducer();

export default notificationsReducer;