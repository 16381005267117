import { useState } from "react";
import { useTranslation } from "react-i18next";

const useHandleDirection = ( language ) => {
    const { i18n } = useTranslation();
    const [ lang ] = useState(i18n.language ? i18n.language : language);

    const handleDirection = ( direction ) => {
        let res = direction;
        if(lang == "pt" && direction == "E"){
          res = "L";
        }
        return res;
    }

    return { handleDirection };
}

export default useHandleDirection;