import React from 'react';
import styled from 'styled-components';
import LogoLs from '../assets/logo/white-logo.svg';
import LogoFw from '../assets/logo/fw.svg';
import { ImPlus } from "react-icons/im";
import { useTranslation } from 'react-i18next';

const FreewavesBanner = () => {

    const { t } = useTranslation();

    return (
        <Container className='shadow-lg' href='https://noticias.lineup.surf/2023/12/19/como-me-impacta-siendo-usuario-de-freewaves/'>

            <div id="welcome-banner" className="">
                <h1 className="">
                {t("freewaves_banner_title")}
                </h1>
                <p>{t("freewaves_banner_description")}<span>{t("freewaves_banner_link")}</span></p>
            </div>

            <LogosContainer id="logo-box">
                <img
                    src={LogoFw}
                    alt=''
                    className=''
                />
                <ImPlus />
                <img
                src={LogoLs}
                alt=''
                className=''
                id="lineup-logo"
                />
            </LogosContainer>

        </Container>
    )
}

// Freewaves adquisition banner
export const Container = styled.a`
    background: rgb(43, 82, 131);
    color: #fff;
    display: flex;
    justify-content: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow: hidden;
    align-items: center;
    width: 100%;
    height: 6rem;
    position: relative;
    gap: 4rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    :hover{
        color: white;
        text-decoration: none;
    }

    #lineup-logo{
        height: 42px !important;
    }

    #welcome-banner{
        display: flex;
        gap: 5px;
        flex-direction: column;
        text-align: center;

        *{
            margin: 0;
        }

        span{
            font-weight: bold;
            text-decoration: underline;
        }
    }

    #logo-box{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h1{
        font-size: 18px;
        color: white;


        strong{
            text-decoration: underline;
        }
    }

    a{
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
    }

    img{
        min-height: 0px; 
        height: 32px !important;
        :hover{
            background: transparent;
        }
    }

    @media(max-width: 1000px){
        flex-direction: column-reverse;
        height: 9rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-align: center;
        gap: 5px;

        h1{
            font-size: 16px;
        }

        p{
            font-size: 14px;
        }

        img{
            height: 24px !important;
        }

        #lineup-logo{
            height: 28px !important;
        }

        #logo-box{
            scale: 1.2;
        }
    }
`;

export const LogosContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img{
        min-height: 0px; 
        height: 32px !important;
        :hover{
            background: transparent;
        }
    }

    #lineup-logo{
        height: 42px !important;
    }

    @media(max-width: 1000px){
        img{
            height: 24px !important;
        }

        #lineup-logo{
            height: 28px !important;
        }
    }
`;


export default FreewavesBanner