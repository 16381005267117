import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Slide from "react-reveal/Slide";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BiDislike, BiLike} from 'react-icons/bi';
import nps from "../modules/nps";
import store from "../utils/store";
import { useTranslation, Trans } from "react-i18next";
import constant from "../utils/constant";
import { AiOutlineClose } from "react-icons/ai";
import utils from "../utils/utils";
import HeadShake from 'react-reveal/HeadShake';

const NpsComponent = (props) => {
  const { t } = useTranslation();
  const [score, setScore] = useState(-1);
  const [likeScore, setLikeScore] = useState(null);
  const [view, setView] = useState("score");
  const [loading, setLoading] = useState(false);
  const [hideFeedback, setHideFeedback] = useState(true);
  const [userCanRate, setUserCanRate] = useState(false);
  const [npsType, setNpsType] = useState(constant["NPS_TYPE"]["WEBAPP"]);
  const [spotId, setSpotId] = useState("");
  const [error, setError] = useState(false);
  const [previousView, setPreviousView] = useState("")

  useEffect(() => {

    const getNps = async () => {
      if (loading === false && props.status === "logged") {
        const response = await nps.getNpsCheck(props.user.id, props.user.createdAt);
        if (response === true) {
          setHideFeedback(false);
          setNpsType(constant["NPS_TYPE"]["WEBAPP"]);
          setUserCanRate(response);
        }
        setLoading(true);
      }
    };
      getNps();
    
  }, []);

  useEffect(() => {}, [hideFeedback]);

  useEffect(() => {
    if (props.npsActive === true) {
      if (props.npsType !== undefined && props.npsType !== null) {
        if (props.npsType !== npsType) {
          resetNps();
        }
        setNpsType(props.npsType);
      }
      if (props.npsSpot !== undefined) {
        setSpotId(props.npsSpot);
      }
      setHideFeedback(!hideFeedback);
      store.dispatch({
        type: "NPS_ACTIVE",
        data: {
          npsActive: false,
          npsType: constant["NPS_TYPE"]["WEBAPP"],
        },
      });
    }
  }, [props.npsActive]);

  const sendNpsScore = (score) => {
    setScore(score);
    nps.addScore(props.user.id, score, npsType, spotId);
    setPreviousView(view);
    setView("set-comment");
  };

  const sendNpsLikeScore = async (like) => {
    setLikeScore(like);
    nps.addScore(props.user.id, like, npsType, spotId, props.npsTime);
    setPreviousView(view);
    like === true ? setView("end-nps") : setView("set-comment");
  };

  const sendComment = () => {
    const userComment = document.getElementById("userComment").value.trim();
    
    if(score < 7 && !utils.handleFields(userComment, 7)){
      setError(true);
      return;
    }
    nps.addComment(props.user.id, userComment, npsType, spotId);
    setView("end-nps");
  };

  const closeRate = () => {
    handleNpsButton(true);
    if (userCanRate === true) {
      nps.closeRate(props.user.id);
    }
  };

  const handleNpsButton = (feedAction) => {
    if (view === "end-nps" || npsType !== constant["NPS_TYPE"]["WEBAPP"]) {
      resetNps();
    }
    setSpotId("");
    setHideFeedback(feedAction);
    setNpsType(constant["NPS_TYPE"]["WEBAPP"]);
  };

  const resetNps = () => {
    setView("score");
    setScore(-1);
  };

  const goBack = () => {
    setScore(-1);
    setView(previousView);
    setError(false); 
  }

  return (
    <>
      {props.status === "logged" && (
        <>
          <NpsButtonFeedback style={{ right: hideFeedback === true ? "-61px" : "-200px" }} onClick={() => handleNpsButton(false)}>
            {t("give_feedback_button")}
          </NpsButtonFeedback>
          <FeedbackBox>
            <Slide left className="slide-feedback">
              <NpsBox style={{ right: hideFeedback === true ? "-700px" : "0px", display: hideFeedback === true ? "none" : "block" }}>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => closeRate()}></button>
                {view === "score" && (
                  <div className="nps-score">
                    <h6>{t(npsType + "_nps_title")}</h6>
                    <h1>
                      <Trans
                        i18nKey={t(npsType + "_nps_subtitle")} // optional -> fallbacks to defaults if not provided
                        values={{ name: props.user.name }}
                      />
                    </h1>
                    <NpsScores>
                    { npsType === "INTERPRETER" ?
                      <>
                        <BiLike onClick={() => sendNpsLikeScore(true)}></BiLike>
                        <BiDislike onClick={() => sendNpsLikeScore(false)}></BiDislike>
                      </> :
                      <>
                        <div>
                          {Array.from([0, 1, 2, 3, 4, 5], (value, i) => (
                            <span key={i}>
                              <NpsInput onClick={() => sendNpsScore(value)}>{value}</NpsInput>
                            </span>
                          ))}
                        </div>
                        <div>
                          {Array.from([6, 7, 8, 9, 10], (value, i) => (
                            <span key={i}>
                              <NpsInput className={value === 10 ? "last-score" : ""} onClick={() => sendNpsScore(value)}>
                                {value}
                              </NpsInput>
                            </span>
                          ))}
                        </div>
                      </>
                    }
                    </NpsScores>
                  </div>
                )}
                {view === "set-comment" && (
                  <div className="nps-comment">
                    <h1>
                      <AiOutlineArrowLeft onClick={() => goBack()} />
                      {t(npsType + "_nps_reason")}
                    </h1>
                    <NpsComment>
                    <HeadShake spy={error}>
                      <textarea id="userComment" className={error === true ? "error" : ""}></textarea>
                    </HeadShake>
                      <a onClick={() => sendComment()} style={{ right: hideFeedback === true ? "-600px" : "95px" }}>
                        {t(npsType + "_nps_button_send")}
                      </a>
                    </NpsComment>
                  </div>
                )}
                {view === "end-nps" && (
                  <div className="end-nps">
                    <img crossOrigin="anonymous"src={require("../assets/icons/shaka.svg")} />
                    <h1>{t(npsType + "_nps_thanks")}</h1>
                  </div>
                )}
              </NpsBox>
            </Slide>
          </FeedbackBox>
        </>
      )}
    </>
  );
};

const FeedbackBox = styled.div`
  .react-reveal {
    z-index: 999999;
    position: fixed;
    bottom: 20px;
    right: 0;
    @media (max-width: 1000px) {
       {
        width: 100%;
        z-index: 99999999;
        bottom: 0;
      }
    }


  }
`;

const NpsButtonFeedback = styled.a`
  transform: rotate(270deg);
  position: fixed;
  bottom: 147px;
  border-radius: 5px;
  z-index: 9999;
  background-color: #425781;
  padding: 20px 33px;
  -webkit-box-shadow: 0px 0px 3px 2px rgba(66, 87, 129, 0.83);
  box-shadow: 0px 0px 3px 2px rgba(66, 87, 129, 0.83);
  color: #fff;
  font-weight: 400;
  transition: 0.3s; /* Add transition on hover */

  :hover {
    text-decoration: none;
    background-color: #425781;
    color: #fff;
  }

  @media (max-width: 900px) {
    display: none !important;
  }
`;

const NpsBox = styled.section`
  position: fixed;
  bottom: 100px;
  transition: 0.3s; /* Add transition on hover */
  z-index: 999;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  height: 170px;
  width: 600px;
  -webkit-box-shadow: 0px 0px 3px 2px rgba(66, 87, 129, 0.83);
  box-shadow: 0px 0px 3px 2px rgba(66, 87, 129, 0.83);
  padding: 10px;
  margin-top: -10px;

  .btn-close{
    position: absolute !important;
    right: 12px !important;
    background-color: transparent;
    border: none;
    svg{
      width: 22px;
      height: 22px;
    }
  }

  img {
    max-width: 42px;
    margin-top: 30px;
  }

  @media (max-width: 900px) {
    width: 100vh;
    max-width: 100vh;
    bottom: 0 !important;

    max-width: 100%;
    height: 210px;

    a {
    position: initial;
    text-align: center;
    display: block;
    position: sticky !important;
    margin-right: auto;
    margin-left: auto;
    max-width: 71% !important;
    margin-top: 15px;
    width: 71% !important;
    right: 0 !important;
  }
  }

  }

  h1 {
    margin-top: 10px;
    font-size: 1em;
    padding: 0px 20px 10px 20px;
    margin-bottom: 5px;
  }

  h6{
    margin-top: 10px;
    font-size: 0.85em;
    font-weight: 300;
    margin-bottom: 0px !important;

    @media (max-width: 1000px) {
      margin-top: 30px;
    }
  }

  .nps-score {
    h1 {
      margin-bottom: 10px !important;
    }
  }

  a {
    background-color: #425781;
    color: #fff;
    padding: 5px 30px;
    border-radius: 5px;
    color: #fff !important;
    max-width: 100px;
    position: fixed;
    right: 95px;
    bottom: 107px;    
  }

  @media (max-width: 900px) {
    a {
      bottom: -50px;
    right: 0px;
    left: 0px;
    position: absolute !important;
    z-index: 99999;
    }
  }

  .error-text{ margin-bottom: 0; }

`;

const NpsScores = styled.section`
  display: inline-flex;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 900px) {
    display: grid;
  }

  div {
    display: inline-flex;
    margin: auto;
  }

  .last-score{
    padding-left 7px !important;
  }

  svg{
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }

`;

const NpsComment = styled.section`
  display: grid;

  textarea {
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    resize: none;
  }

  .error{
    border: 1px solid red;
  }
`;

const NpsInput = styled.div`

  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding-left: 11px;
  padding-top: 3px;  
  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  margin-right: 5px !important;
  margin-left: 5px !important;
  width: 50%

  :hover {
    background: #425781;
    color: #fff;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  npsActive: state.npsActive,
  npsType: state.npsType,
  npsSpot: state.npsSpot,
  npsTime: state.npsTime
});

export default connect(mapStateToProps)(NpsComponent);
