import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_METRICS_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  (res) => {
    return res["data"]["data"];
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
      }
      // else if (err.response.status === 401) {
      //   auth.logout();
      //   window.location.href = process.env.REACT_APP_BASE_HREF;
      // }
      // else if (err.response.status === 403 && err.response.data) {
      //   auth.logout();
      //   window.location.href = process.env.REACT_APP_BASE_HREF;
      // }
      // else if (err.response.status === 500 && err.response.data) {
      //   //auth.logout();
      // }
    }
    return Promise.reject(err);
  }
);




export default instance;
