import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

function DirectRoute({ children, isAuthenticated, ...rest }) {
  return (
    <>
      <Route {...rest} render={({ location }) => <>children</>} />
    </>
  );
}

export default DirectRoute;
