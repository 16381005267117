import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ClockComponent = (props) => {
  const [date, setDate] = React.useState("");
  const { t, i18n } = useTranslation();


  useEffect(() => {
    if (date === "") {
      setDate(getCurrentHour());
    }
    const timerID = setInterval(() => tick(), 5000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  const tick = () => {
    setDate(getCurrentHour());
  };

  const convertDateToUTC = (date) => {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  };

  const getCurrentHour = () => {
    const date = new Date();
    let newDate = convertDateToUTC(date);
    newDate.setHours(newDate.getHours() + props.timezone);
    let hour = newDate.getHours();
    let minutes =  newDate.getMinutes()
    if(hour < 10) hour = "0" + hour;
    if(minutes < 10) minutes = "0" + minutes
    return hour + ":" + minutes;
  };

  return <ClockText>{date !== "" && date}</ClockText>;
};

const ClockText = styled.p`
  text-align: center;
  margin-top: -15px;
  margin-bottom: 15px;
  font-size: 0.8em;
  font-family: Poppins;
  color: black;
`;

export default ClockComponent;
