import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Label, FormGroup, Input } from "reactstrap";
import { AiOutlineWhatsApp, AiOutlineClose } from "react-icons/ai";
import Slide from "react-reveal/Slide";
import { connect } from "react-redux";
import Fade from 'react-reveal/Fade';
import auth from "../modules/auth";
import store from "../utils/store";
import constant from "../utils/constant";
import utils from "../utils/utils";
import animationData from "../assets/animations/document.json";
import Lottie from "react-lottie";
import { Spinner } from "reactstrap";

const DocumentModalComponent = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [needToShow, setNeedToShow] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(false)
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {

  }, [isOpen])

  useEffect(() => {
    if (props.user && props.user.isPremium === true) {
      if (props.user.document === null && props.askDocument !== true) {
        setNeedToShow(true)
      }
    }
  }, []);

  const handleModal = () => {
    store.dispatch({
      type: "ASK_DOCUMENT",
      data: true,
    });
    setNeedToShow(false)
  }

  const updateDocument = () => {
    setError(false)
    setIsUpdating(true);
    const documentId = document.getElementById("documentId").value;
    if (documentId === "") {
      setTimeout(() => {
        setError(true);
        setIsUpdating(false);
      }, 200);
    } else {
      auth.updateDocument(documentId);
      setTimeout(() => {
        handleModal();
        setIsUpdating(false);
      }, 500);
    }
  }

  return (
    <>
      {needToShow === true && (
        <PopUpContainer>
          <Slide top>
            <PopUpBox>
              <AiOutlineClose className="close-button" onClick={() => handleModal(false)} />
              <Lottie options={defaultOptions}
                height={130}
                width={210}
                style={{ paddingTop: '1em' }} />
              <PopUpTitle>{t("document_modal_title")}</PopUpTitle>
              <PopUpSubTitle>{t("document_modal_sub_title")}</PopUpSubTitle>
              <PopUpText>{t("document_modal_sub_description")}</PopUpText>
              <input type="text" placeholder="Ingrese su documento de identidad" id="documentId" className={error === true ? "error-input" : ""} />
              <PopUpButtonContainer>
                <PopUpButton>
                  <button onClick={() => updateDocument(false)} disabled={isUpdating}>{isUpdating ? <Spinner /> : t("document_modal_button")}</button>
                </PopUpButton>
              </PopUpButtonContainer>
            </PopUpBox>
          </Slide>
        </PopUpContainer>
      )}
    </>
  );
};

const PopUpImage = styled.img`
 width: 170px;
 -webkit-filter: drop-shadow(-1px 2px 1px #00000040);
 filter: drop-shadow(-1px 2px 1px #00000040);
 margin-top: 20px;
 margin-bottom: 0px;
`;

const PopUpButton = styled.li`
  width: 100%;
  list-style: none;

  button {
    display: block;
    height: 60px;
    line-height: 60px;
    color: #fff;
    transition: background-color 0.2s;
    font-weight: 600;
    width: 100%;
    background: #004989;
    border: none;
    border-radius: 0 0 0.25em 0.25em;
    font-family: "Poppins";

    svg{
      width: 24px;
      height: 24px;
      margin-top: -3px;
    }

  }
`;

const PopUpButtonContainer = styled.ul`
  padding: 0 !important;
  color: #fff;

  li:last-child a {
    background: #004989;
    border-radius: 0 0 0.25em 0.25em;
    border: none;
    width: 100%;
    font-family: "Poppins";
  }

  .spinner-border{
    margin-top: 14px;
  }

  a:hover{
    text-decoration: none !important;
  }

`;

const PopUpTitle = styled.h1`
    padding: 30px;
    padding-top: 8px;
    padding-bottom: 0px;
    font-size: 1.22em;
    font-weight: 600;
`;

const PopUpSubTitle = styled.h6`
    padding: 5px 20px;
    padding-bottom: 0px;
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 0.8em;
    font-weight: 600;
`;

const PopUpDescription = styled.p`
    font-weight: 400;
    padding: 5px 20px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    font-size: 0.9em;
`;

const PopUpText = styled.p`
  font-weight: 400;
  padding: 5px 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 0.8em;
  font-family: "Poppins";
`;

const PopUpContainer = styled.div`
  position: fixed;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  opacity: 0;
  visibility: hidden;
  z-index: 9999999999 ;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;

  input{
    margin: 17px;
    width: 85%;
    height: 35px;
    font-size: 0.8em;
    font-family: "Poppins";
    border: none;
    border-bottom: 1.5px solid #b5acac;
  }

  .error-input{
    border-bottom: 2px solid #ff5858;
  }

`;

const PopUpBox = styled.div`
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: 4em auto;
  background: #fff;
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  text-align: center;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-backface-visibility: hidden;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  top: 0;
  transform: translateY(0);
  margin-bottom: 100px;

  .close-button{
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 99999;
    right: 10px;
    top: 10px;
    background-color: #000;
    border-radius: 50%;
    padding: 2px;
    color: #fff;
    cursor: pointer;
  }
`;

const mapStateToProps = (state) => ({
  status: state.status,
  user: state.user,
  askDocument: state.askDocument


});

export default connect(mapStateToProps)(DocumentModalComponent);

