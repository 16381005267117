import instance from "./service";
import constant from '../utils/constant';
import utils from "../utils/utils";

class Nps {
  async addScore(userId, score, npsType, spotId) {
    instance.post("/addScore", { user_id: userId, score: score,  platform: npsType, spot_id: spotId});
  }

  async addComment(userId, comment, npsType, spotId) {
    instance.post("/addComment", { user_id: userId, comment: comment, platform:  npsType, spot_id: spotId });
  }

  async closeRate(userId, comment) {
    instance.post("/closeRate", { user_id: userId });
  }

  async getNpsCheck(userId, createdAt) {
    const result = await instance.get("/canRate", { Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH), params: { user_id: userId, created_at: createdAt } });
    return result.can_rate;
  }
}

const nps = new Nps();

export default nps;
