import constant from '../utils/constant';
import instance from "./service";
import utils from "../utils/utils";

class NotificationsAPI {
  async getUnread() {
    try {
      let result = await instance.get("/notifications/getUnread", { 
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH) 
      });
      return result;
    } catch (error) {
      console.log('Error at Notifications.getUnread: ' + error)
      return [];
    }
  }

  async getAll() {
    try {
      let result = await instance.get('/notifications/getAll', { 
        Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH) 
      });
      return result;
    } catch (error) {
      console.log('Error at Notifications.getAll: ' + error)
      return [];
    }
  }

  async markAsRead(notificationIdList) {
    try {
      await instance.post("/notifications/markAsRead", { 
          notificationIdList: notificationIdList,
          Authorization: "Bearer " + utils.getCookieValue(constant.COOKIE_AUTH) 
      });
      return true;
    } catch (error) {
      console.log('Error at Notifications.markAsRead: ' + error)
      return false;
    }
  }
}

const notificationsAPI = new NotificationsAPI();

export default notificationsAPI;
